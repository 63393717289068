import React, { FunctionComponent } from "react";
import { Card, Container } from "react-bootstrap";

export const InfoPage: FunctionComponent<{}> = () => {
	return (
		<Container>
			<div className={"m-3"}>
				<Card>
					<Card.Header>エラー</Card.Header>
					<Card.Body>
						<div>サーバーが混雑しているため、通信エラーになりました。</div>
						<div>恐れ入りますが、以下までお問合せをお願いいたします。</div>
						<br />
						<a href="mailto:user@dammy.com">customersuccess@supcolo.jp</a>
					</Card.Body>
				</Card>
			</div>
		</Container>
	);
};

import { DefaultSchema, Key } from "@lu/repository";
import { AggregateJob as AggregateConfig } from "@lu/muscat-analytics-library/dist/model/aggregate-job";

export const importType = {
	manual: "手動アップロード",
	all: "自動（全データ）",
	completed: "自動（completed）",
} as const;

export type ImportType = keyof typeof importType;

export const importTypes = Object.keys(importType) as ImportType[];

export type AggregateJob = {
	job_id: Key; // アンケートID、アンケートNoはjobIdから取って戻す
	aggregate_no: number; // 集計No
	name: string; // 集計名称
	import_type: ImportType;
	uploadDateTime: string;
} & DefaultSchema;

// export type AggregateJobWithEnqueteNo = AggregateJob & { enqueteNo: number | Seq };

export type AggregateJobWithConfig = AggregateJob & {
	config: AggregateConfig;
};

import { ContentsState } from "../types/contents-state";

export type State = {
	mediaId: ContentsState<number>;
	modalShow: boolean;
	reloading: boolean;
};

export const initState: State = {
	mediaId: { state: "loading" },
	modalShow: false,
	reloading: false,
};

import classNames from "classnames";
import React, { CSSProperties, FunctionComponent, useMemo } from "react";

type Props = {
	align?: "center" | "left" | "right";
	className?: string;
	style?: CSSProperties;
	wide?: boolean;
};

export const ButtonsContainer: FunctionComponent<Props> = ({ children, align, className, style, wide }) => {
	const _style = useMemo(
		() => ({
			...style,
			justifyContent: align == "right" ? "flex-end" : align,
		}),
		[align, style]
	);

	return (
		<div className={classNames("buttons-container", className, { "buttons-container--wide": wide })} style={_style}>
			{children}
		</div>
	);
};

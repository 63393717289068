import React, { FunctionComponent } from "react";
import { AxisLabel } from "../../../../../../../../lib/aggregate/axis";
import { ResultViewType } from "../../../../../../../../types/result-view-type";
import { LabelCell } from "./label-cell";

type Props = {
	labels: AxisLabel[];
	selectedRows: Set<number>;
	type: ResultViewType;
	onClickRowHeader: (rows: number[]) => void;
};

export const LabelCells: FunctionComponent<Props> = React.memo(({ labels, ...props }) => {
	return (
		<>
			{labels.map((label, i) => (
				<LabelCell key={i} axisLabel={label} index={i} {...props} />
			))}
		</>
	);
});

import classNames from "classnames";
import React, { FunctionComponent } from "react";

type Props = {
	className?: string;
	disabled?: boolean;
	mode?: "vertical" | "horizontal";
};

export const Grip: FunctionComponent<Props> = React.memo(({ className, disabled, mode = "vertical", ...props }) => {
	return (
		<div className={classNames("grip", className, { "grip--disabled": disabled })} role="handle" {...props}>
			<i className={`bi-grip-${mode}`} />
		</div>
	);
});

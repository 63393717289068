import React, { forwardRef, ForwardRefRenderFunction, Ref } from "react";
import Button from "react-bootstrap/Button";

type Props = {
	ref: Ref<HTMLButtonElement>;
	disabled?: boolean;
	variant?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const GearButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = React.memo(
	forwardRef(({ children, disabled, variant = "outline-gray", onClick }, ref) => {
		return (
			<Button ref={ref} disabled={disabled} variant={variant} onClick={onClick}>
				<i className="bi-gear" />
				{children && <span>{children}</span>}
			</Button>
		);
	})
);

import { GetAggregateJobListResponse } from "../../../../../server/types/request/internal/aggregate";
import { ContentsState } from "../../../types/contents-state";

export type State = {
	aggregates: ContentsState<GetAggregateJobListResponse>;
};

export const initState: State = {
	aggregates: { state: "loading" },
};

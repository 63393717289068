import React, { CSSProperties, FunctionComponent, useCallback, useReducer } from "react";
import { ResultViewType } from "../../../../../types/result-view-type";
import { CrossObj } from "../../../../../lib/aggregate/converter";
import { reducer } from "./reducer";
import { initState } from "./state";
import { ValuesArea } from "./values-area";
import { HeaderArea } from "./header-area";

type Props = {
	cross: CrossObj;
	style?: CSSProperties;
	horizontal: boolean;
	type: ResultViewType;
};

export const CrossArea: FunctionComponent<Props> = React.memo(({ cross, style, horizontal, type }) => {
	const { bigTitle, condition, datas, labels, options, qunename, selections, title } = cross;

	const [state, dispatch] = useReducer(reducer, initState);

	const handleClickCell = useCallback(
		(col: number, row: number) => dispatch({ type: "select_cell", payload: { col, row } }),
		[]
	);

	const handleClickColHeader = useCallback((payload: number[]) => dispatch({ type: "select_col", payload }), []);

	const handleClickRowHeader = useCallback((payload: number[]) => dispatch({ type: "select_row", payload }), []);

	return (
		<div className="cross-area" id={`cross-${qunename}${condition}`} style={style}>
			{condition && <div className="cross-area__big-title">{`【${condition}】`}</div>}
			{bigTitle && <div className="cross-area__big-title">{bigTitle}</div>}
			<div className="cross-area__title">
				{qunename}.{title}
			</div>
			<div className="cross">
				<table className="cross__table">
					<HeaderArea
						labels={labels}
						options={options}
						selectedCols={state.selectedCols}
						selections={selections}
						horizontal={horizontal}
						onClickColHeader={handleClickColHeader}
					/>
					<tbody>
						<ValuesArea
							datas={datas}
							labels={labels}
							options={options}
							selections={selections}
							horizontal={horizontal}
							type={type}
							onClickCell={handleClickCell}
							onClickRowHeader={handleClickRowHeader}
							{...state}
						/>
					</tbody>
				</table>
			</div>
		</div>
	);
});

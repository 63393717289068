import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { get, makeError, postFormData } from "../../../../lib/request";
import { GetAggregateResponse } from "../../../../../../server/types/request/internal/aggregate";
import { LoadingSpinner } from "../../../parts/loadings";
import { ErrorsViewer } from "../../../parts/errors-viewer";
import { TitledContainer } from "../../../parts/titled-container";
import { internalApiEndpoint } from "../../../../../../server/router/api/internal/endpoint";
import Page from "../../../parts/page";
import EditorPageHeader from "../../../parts/editor-page-header";
import EditorPageBody from "../../../parts/editor-page-body";
import { GetJobOneResponse } from "../../../../../../server/types/request/internal/job";
import { enqueteType } from "../../../../../../server/repositories/job/model";
import EditorPageHeaderRow from "../../../parts/editor-page-header/row";
import { useHistory } from "react-router";
import { reducer } from "./reducer";
import { initState } from "./state";
import { ButtonsContainer } from "../../../parts/buttons-container";
import { Button } from "react-bootstrap";
import { FileInput } from "../../../parts/file-input";
import { endpoint } from "../../../../routes/endpoints";
import { MessageToast } from "../../../parts/message-toast";
import { isUTF8WithBOM } from "../../../../utils/encodingCheckers";

type Props = {
	id: string;
	jobId: string;
};

export const UploadRawdataPage: FunctionComponent<Props> = React.memo(({ id, jobId }) => {
	const { t } = useTranslation();

	const history = useHistory<{ message?: string; prev?: string }>();

	const [state, dispatch] = useReducer(reducer, initState);

	const { aggregateJob, job } = state;

	useEffect(() => {
		if (!id) return;
		get<GetAggregateResponse>(`${internalApiEndpoint.aggregateJob}/${id}`)
			.then((res) => {
				dispatch({ type: "aggregate", payload: { state: "loaded", value: res.data.aggregateJob } });
			})
			.catch((error) => {
				dispatch({ type: "aggregate", payload: { state: "failed", info: makeError(error) } });
			});
	}, [id]);

	useEffect(() => {
		get<GetJobOneResponse>(`${internalApiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({
					type: "job",
					payload: { state: "loaded", value: res.data.job },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: "job", payload: { state: "failed", info: makeError(error) } });
			});
	}, [jobId]);

	const handleChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: "file", payload: event.target.files[0] });

		const reader = new FileReader();
		reader.onload = () => dispatch({ type: "fileInvalid", payload: !isUTF8WithBOM(reader.result.toString()) });

		reader.readAsBinaryString(event.target.files[0]);
	}, []);

	const handleClickCancel = useCallback(
		() =>
			history.push(history.location.state?.prev ?? `/${jobId}/${endpoint.aggregates}`, {
				message: t("alert_message.canceled"),
			}),
		[jobId]
	);

	const handleClickUpload = useCallback(() => {
		if (!state.file) return;

		const data = new FormData();

		data.append("file", state.file);

		dispatch({ type: "progress_state", payload: "progress" });

		postFormData(`${internalApiEndpoint.aggregateRawdata}/${id}`, data)
			.then(() => {
				dispatch({ type: "progress_state", payload: "done" });

				history.push(history.location.state?.prev ?? `/${jobId}/${endpoint.aggregates}`, {
					message: t("alert_message.upload_succeed"),
				});
			})
			.catch((error) => {
				dispatch({ type: "message_info", payload: makeError(error) });
			});
	}, [state.file]);

	return (
		<Page className="upload-rawdata-page" fluid>
			<Page.Header title={t("page_title.upload_rawdata")} />
			{state.progressState === "progress" && <MessageToast>{t("upload_rawdata_page.progress")}</MessageToast>}
			<Page.Contents className="upload-rawdata-page__contents">
				{aggregateJob.state === "loaded" && job.state === "loaded" && (
					<>
						<ErrorsViewer info={state.info} />
						<EditorPageHeader>
							<EditorPageHeader.Title>{t("upload_rawdata_page.job_details")}</EditorPageHeader.Title>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_title")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{job.value.enquete_title}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_type")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{enqueteType[job.value.type]}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
						</EditorPageHeader>
						<EditorPageBody>
							<EditorPageBody.Title>{t("upload_rawdata_page.upload_file")}</EditorPageBody.Title>
							<TitledContainer title={t("upload_rawdata_page.file")}>
								<FileInput
									id="upload-rawdata"
									accept="text/csv"
									isInvalid={state.fileInvalid}
									feedback={state.fileInvalid ? t("upload_rawdata_page.invalid_encoding") : undefined}
									disabled={state.progressState === "progress"}
									value={state.file}
									onChange={handleChangeFile}
								/>
							</TitledContainer>
						</EditorPageBody>
						<ButtonsContainer align="center">
							<Button variant="gray" onClick={handleClickCancel}>
								{t("upload_rawdata_page.cancel")}
							</Button>
							<Button
								disabled={state.progressState === "progress" || state.file == null || state.fileInvalid}
								variant="secondary"
								onClick={handleClickUpload}
							>
								{t("upload_rawdata_page.upload")}
							</Button>
						</ButtonsContainer>
					</>
				)}
				{(aggregateJob.state === "loading" || job.state === "loading") && <LoadingSpinner />}
				{aggregateJob.state === "failed" && <ErrorsViewer info={aggregateJob.info} />}
			</Page.Contents>
		</Page>
	);
});

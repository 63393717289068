import React, { FunctionComponent } from "react";
import Spinner from "react-bootstrap/Spinner";

export const LoadingSpinner: FunctionComponent = () => {
	return (
		<div className="loading-spinner">
			<Spinner animation="border" variant="secondary" />
		</div>
	);
};

import classNames from "classnames";
import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { MouseEvent } from "react";
import { ChangeEvent } from "react";
import { Form } from "react-bootstrap";

type Props = {
	checked?: boolean;
	disabled?: boolean;
	id: string;
	label?: ReactNode;
	variant?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const Checkbox: FunctionComponent<Props> = ({ disabled, label, variant = "primary", ...props }) => {
	const labelNode = useMemo(
		() => (
			<>
				<span className="custom-checkbox__checkbox" tabIndex={0}>
					<i className={classNames("bi-check-square-fill", "custom-checkbox__checkbox--checked", `text-${variant}`)} />
					<i className={classNames("bi-square", "custom-checkbox__checkbox--unchecked", `text-${variant}`)} />
				</span>
				<span className="custom-checkbox__text">{label}</span>
			</>
		),
		[label]
	);

	return (
		<Form.Check
			className={classNames({ "custom-checkbox--disabled": disabled })}
			bsPrefix="custom-checkbox"
			disabled={disabled}
			label={labelNode}
			type="checkbox"
			{...props}
		/>
	);
};

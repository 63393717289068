import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { get, makeError, post } from "../../../../lib/request";
import { GetAggregateResponse } from "../../../../../../server/types/request/internal/aggregate";
import { LoadingSpinner } from "../../../parts/loadings";
import { ErrorsViewer } from "../../../parts/errors-viewer";
import { internalApiEndpoint } from "../../../../../../server/router/api/internal/endpoint";
import Page from "../../../parts/page";
import EditorPageHeader from "../../../parts/editor-page-header";
import EditorPageBody from "../../../parts/editor-page-body";
import { GetJobOneResponse } from "../../../../../../server/types/request/internal/job";
import { enqueteType } from "../../../../../../server/repositories/job/model";
import EditorPageHeaderRow from "../../../parts/editor-page-header/row";
import { useHistory } from "react-router";
import { reducer } from "./reducer";
import { initState } from "./state";
import { ButtonsContainer } from "../../../parts/buttons-container";
import { Button } from "react-bootstrap";
import { endpoint } from "../../../../routes/endpoints";
import { MessageToast } from "../../../parts/message-toast";

type Props = {
	id: string;
	jobId: string;
};

export const SyncRawdataPage: FunctionComponent<Props> = React.memo(({ id, jobId }) => {
	const { t } = useTranslation();

	const history = useHistory<{ message?: string; prev?: string }>();

	const [state, dispatch] = useReducer(reducer, initState);

	const { aggregateJob, job } = state;

	useEffect(() => {
		if (!id) return;
		get<GetAggregateResponse>(`${internalApiEndpoint.aggregateJob}/${id}`)
			.then((res) => {
				dispatch({ type: "aggregate", payload: { state: "loaded", value: res.data.aggregateJob } });
			})
			.catch((error) => {
				dispatch({ type: "aggregate", payload: { state: "failed", info: makeError(error) } });
			});
	}, [id]);

	useEffect(() => {
		get<GetJobOneResponse>(`${internalApiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({
					type: "job",
					payload: { state: "loaded", value: res.data.job },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: "job", payload: { state: "failed", info: makeError(error) } });
			});
	}, [jobId]);

	const handleClickCancel = useCallback(
		() =>
			history.push(history.location.state?.prev ?? `/${jobId}/${endpoint.aggregates}`, {
				message: t("alert_message.canceled"),
			}),
		[jobId]
	);

	const handleClickSync = useCallback(() => {
		dispatch({ type: "progress_state", payload: "progress" });

		post(`${internalApiEndpoint.aggregateAutoRawdata}/${id}`, {})
			.then(() => {
				dispatch({ type: "progress_state", payload: "done" });

				history.push(history.location.state?.prev ?? `/${jobId}/${endpoint.aggregates}`, {
					message: t("alert_message.sync_succeed"),
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: "message_info", payload: makeError(error) });
			});
	}, []);

	return (
		<Page className="sync-rawdata-page" fluid>
			<Page.Header title={t("page_title.sync_rawdata")} />
			{state.progressState === "progress" && <MessageToast>{t("sync_rawdata_page.progress")}</MessageToast>}
			<Page.Contents className="sync-rawdata-page__contents">
				{aggregateJob.state === "loaded" && job.state === "loaded" && (
					<>
						<ErrorsViewer info={state.info} />
						<EditorPageHeader>
							<EditorPageHeader.Title>{t("sync_rawdata_page.job_details")}</EditorPageHeader.Title>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_title")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{job.value.enquete_title}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_type")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{enqueteType[job.value.type]}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
						</EditorPageHeader>
						<EditorPageBody>
							<EditorPageBody.Title>{t("sync_rawdata_page.sync_current_rawdata")}</EditorPageBody.Title>
						</EditorPageBody>
						<ButtonsContainer align="center">
							<Button variant="gray" onClick={handleClickCancel}>
								{t("sync_rawdata_page.cancel")}
							</Button>
							<Button disabled={state.progressState === "progress"} variant="secondary" onClick={handleClickSync}>
								{t("sync_rawdata_page.sync")}
							</Button>
						</ButtonsContainer>
					</>
				)}
				{(aggregateJob.state === "loading" || job.state === "loading") && <LoadingSpinner />}
				{aggregateJob.state === "failed" && <ErrorsViewer info={aggregateJob.info} />}
			</Page.Contents>
		</Page>
	);
});

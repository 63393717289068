import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";

type Props = {
	index: number;
	selectedCols: Set<number>;
	selection: string;
	onClick: (cols: number[]) => void;
};

export const SelectionCell: FunctionComponent<Props> = React.memo(({ index, selectedCols, selection, onClick }) => {
	const handleClick = useCallback(() => onClick([index]), [index, onClick]);

	return (
		<th
			className={classNames("cross__col", {
				"cross__col--selected": selectedCols.has(index),
			})}
			onClick={handleClick}
		>
			{selection}
		</th>
	);
});

export const internalApiEndpoint = {
	login: "/api/i/login",
	logout: "/api/i/logout",
	authentication: "/api/i/auth",

	account: "/api/i/account",
	organization: "/api/i/organization",
	job: "/api/i/job",
	quota: "/api/i/quota",
	images: "/api/i/images",

	// 集計
	aggregateJob: "/api/i/aggregate-job",
	aggregateJobs: "/api/i/aggregate-jobs",
	aggregateQuestion: "/api/i/aggregate-questions",
	aggregateCrossData: "/api/i/data/cross",
	downloadAggregateCross: "/api/i/download/cross",
	aggregateRawdata: "/api/i/upload/rawdata",
	aggregateAutoRawdata: "/api/i/upload/auto-rawdata",
} as const;

export type InternalApiEndpoint = keyof typeof internalApiEndpoint;

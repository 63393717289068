import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import Form from "react-bootstrap/Form";

type Prop = {
	className?: string;
	title: ReactNode;
};

export const TitledContainer: FunctionComponent<Prop> = React.memo(({ className, title, children }) => {
	return (
		<Form.Group className={classNames("titled-container", className)}>
			<Form.Label className="titled-container__title">{title}</Form.Label>
			<div className="titled-container__inner">{children}</div>
		</Form.Group>
	);
});

import React, { FunctionComponent } from "react";

declare type EditorPageBody = typeof Body & {
	Title: typeof Title;
};

const Body: FunctionComponent = React.memo(({ children }) => {
	return <div className="editor-page-body">{children}</div>;
});

const Title: FunctionComponent = React.memo(({ children }) => {
	return <h5 className="editor-page-body__title">{children}</h5>;
});

const EditorPageBody: EditorPageBody = Body as EditorPageBody;

EditorPageBody.Title = Title;

export default EditorPageBody;

import React, { FunctionComponent, ReactNode } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import classNames from "classnames";

type Props = {
	className?: string;
	header: { label: string; value: string }[];
	items: { [key in string]: ReactNode }[];
	page: number;
	pageCount: number;
	onGet?: (page: number) => void;
};

export const PagingTable: FunctionComponent<Props> = ({
	children,
	className,
	header,
	items,
	page,
	pageCount,
	onGet = () => {
		return;
	},
}) => {
	return (
		<div className={classNames("paging-table", className)}>
			{pageCount > 1 && (
				<div className="paging-table__pagenation">
					<ButtonGroup>
						<Button variant="white" onClick={onGet.bind(undefined, 0)}>
							<i className="bi-chevron-double-left" />
						</Button>
						<Button disabled={page <= 0} variant="white" onClick={onGet.bind(undefined, page - 1)}>
							<i className="bi-chevron-left" />
						</Button>
					</ButtonGroup>
					{page - 2 > 0 && <i className="bi-three-dots" />}
					<ButtonGroup>
						{[...Array(5)].map(
							(_, i) =>
								page + i - 2 >= 0 &&
								page + i - 2 < pageCount && (
									<Button key={i} variant="white" onClick={onGet.bind(undefined, page + i - 2)}>
										{i === 2 ? <b>{page + i - 1}</b> : page + i - 1}
									</Button>
								)
						)}
					</ButtonGroup>
					{page + 2 < pageCount - 1 && <i className="bi-three-dots" />}
					<ButtonGroup>
						<Button disabled={page >= pageCount - 1} variant="white" onClick={onGet.bind(undefined, page + 1)}>
							<i className="bi-chevron-right" />
						</Button>
						<Button variant="white" onClick={onGet.bind(undefined, pageCount - 1)}>
							<i className="bi-chevron-double-right" />
						</Button>
					</ButtonGroup>
				</div>
			)}
			<div className="paging-table__table-wrapper">
				<Table>
					{children}
					<thead>
						<tr>
							{header.map((item) => (
								<th key={item.value}>
									<span>{item.label}</span>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{items.map((item) => (
							<tr key={`table-row-${item[header[0].value]}`}>
								{header.map(({ value }) => (
									<td key={`table-cell-${item[header[0].value]}-${value}`}>
										{value in item && item[value] ? (
											typeof item[value] === "string" ? (
												<span>{item[value]}</span>
											) : (
												item[value]
											)
										) : null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { AggregateEditorPage } from "../../../components/pages/aggregates/editor";

export const AggregateEditorContainer: FunctionComponent = React.memo(() => {
	const { t } = useTranslation();

	const { id, jobId } = useParams<{ id?: string; jobId: string }>();

	useEffect(() => {
		document.title = `${t(`page_title.${id === undefined ? "create" : "edit"}_aggregate`)} - ${t(
			"page_title.site_name"
		)}`;
	}, [id]);

	return <AggregateEditorPage id={id} jobId={jobId} />;
});

import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { internalApiEndpoint } from "../../../../server/router/api/internal/endpoint";
import { AuthenticaionResponse } from "../../../../server/types/request/internal/auth";
import { ErrorPage } from "../../components/pages/error";
import { post } from "../../lib/request";
import { ContentsState } from "../../types/contents-state";

export type WithAuthProps = {
	mediaId: ContentsState<number>;
	onLogin: (account: ContentsState<number>) => void;
};
export const WithAuth: FunctionComponent<WithAuthProps> = React.memo(({ children, mediaId, onLogin }) => {
	const { t } = useTranslation();

	const location = useLocation();

	useEffect(() => {
		if (mediaId.state === "loaded") return;

		post<AuthenticaionResponse>(internalApiEndpoint.authentication)
			.then((res) => {
				if (res.data.media_id) {
					onLogin({ state: "loaded", value: res.data.media_id });
				} else {
					onLogin({ state: "loaded", value: 0 });
				}
			})
			.catch((error) => {
				console.error(error);
				onLogin({ state: "failed", info: error.response.message });
			});
	}, [mediaId.state, onLogin]);

	const render = useCallback(
		() => (
			<>
				{mediaId.state === "loaded" ? (
					children
				) : mediaId.state === "failed" ? (
					<ErrorPage text={t("not_found.text")} title={t("page_title.not_found")} />
				) : null}
			</>
		),
		[mediaId, children, location]
	);

	return render();
});

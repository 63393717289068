import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { UploadRawdataPage } from "../../../components/pages/aggregates/upload-rawdata";

export const UploadRawdataContainer: FunctionComponent = React.memo(() => {
	const { t } = useTranslation();

	const { id, jobId } = useParams<{ id?: string; jobId: string }>();

	useEffect(() => {
		document.title = `${t("page_title.upload_rawdata")} - ${t("page_title.site_name")}`;
	}, [id]);

	return <UploadRawdataPage id={id} jobId={jobId} />;
});

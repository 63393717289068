import classNames from "classnames";
import React, { FunctionComponent, useCallback, useMemo, useReducer } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[\w-]{2,})$/;

const checkEmail = (email: string | number): boolean => {
	return emailRegex.test(email.toString());
};

const getInitialized = (a: boolean, b: boolean): boolean => {
	return a && b;
};

const keyDownListener = (event: React.KeyboardEvent<any>) => {
	if (event.code === "Enter") {
		event.preventDefault();
	}
};

type Props = {
	as?: "input" | "textarea";
	className?: string;
	initialized?: boolean;
	placeholder?: string;
	readOnly?: boolean;
	required?: boolean;
	size?: "sm" | "lg";
	type?: "email" | "number" | "password" | "text";
	value?: number | string;
	onInput?: (text: string) => void;
	validationFunc?: (text: number | string) => { valid: boolean; message?: string };
};

type State = {
	initialized: boolean;
	message?: string;
	valid: boolean;
};

const initialState = (args: { initialized: boolean; message?: string; valid: boolean }): State => {
	return {
		initialized: args.initialized,
		message: args.message,
		valid: args.valid,
	};
};

export const ValidatableInput: FunctionComponent<Props> = React.memo(
	({
		as = "input",
		className,
		initialized = true,
		placeholder,
		readOnly,
		required,
		size,
		type = "text",
		value,
		onInput = () => {
			return;
		},
		validationFunc = () => {
			return { valid: true, message: "" };
		},
	}) => {
		const { t } = useTranslation();

		const _validationFunc = useCallback(
			(v: number | string): { valid: boolean; message?: string } => {
				if (!required && !v) {
					// 必須ではなく入力もない
					return {
						valid: true,
						message: undefined,
					};
				} else if (required && !v) {
					// 必須で入力がない
					return { valid: false, message: t("validatable_input.required_field") };
				} else if (v && type === "email" && !checkEmail(v)) {
					// emailのバリデーションチェック
					return {
						valid: false,
						message: t("validatable_input.not_email"),
					};
				} else {
					// バリデーション関数をチェック
					const res = validationFunc(v);
					if (res.valid) {
						return {
							valid: true,
							message: required ? t("validatable_input.required_field") : undefined,
						};
					} else {
						return {
							valid: false,
							message: res.message,
						};
					}
				}
			},
			[required, validationFunc]
		);

		const reducer = useCallback(
			(state: State, value: string) => {
				const res = _validationFunc(value);
				return { ...state, initialized: false, ...res };
			},
			[_validationFunc]
		);

		const [state, dispatch] = useReducer(reducer, { ..._validationFunc(value), initialized }, initialState);

		const res = useMemo(() => _validationFunc(value), [_validationFunc, value]);
		return (
			<div
				className={classNames("validatable-input", className, {
					"validatable-input--initialized": getInitialized(state.initialized, initialized),
					"validatable-input--invalid": !res.valid,
				})}
			>
				<Form.Control
					className="validatable-input__input"
					as={as}
					isInvalid={!getInitialized(state.initialized, initialized) && !res.valid}
					placeholder={!placeholder && type === "email" ? t("validatable_input.email_placeholder") : placeholder}
					readOnly={readOnly}
					size={size}
					type={type}
					value={value ? value : ""}
					{...(as === "input" && { onKeyDown: keyDownListener })}
					onChange={(e) => {
						onInput(e.target.value);
						dispatch(e.target.value);
					}}
					{...(as === "textarea" && { rows: 5 })}
				/>
				<Form.Text
					className={classNames("validatable-input__annotation", {
						"validatable-input__annotation--visible":
							required || (!getInitialized(state.initialized, initialized) && res.message),
					})}
				>
					{res.message}
				</Form.Text>
			</div>
		);
	}
);

import { questionTemplate, QuestionTemplate } from "@bleu/supcolo-editor";

const childrenTemplate: QuestionTemplate = {
  templateId: "children",
  templateName: "同居している子供の年齢",
  editable: true,
  titleEditable: false,
  title: [
    {
      type: "paragraph",
      children: [
        {
          text: "あなたと同居しているご自身のお子様の年齢（学齢）をすべてお答えください。",
        },
      ],
    },
  ],
  type: "S",
  choiceGroups: [
    {
      id: 1,
      choices: [
        {
          value: 1,
          text: "０歳～１歳未満",
        },
        {
          value: 2,
          text: "１歳～３歳未満",
        },
        {
          value: 3,
          text: "３歳～小学生未満",
        },
        {
          value: 4,
          text: "小学生（１～３年生）",
        },
        {
          value: 5,
          text: "小学生（４～６年生）",
        },
        {
          value: 6,
          text: "中学生",
        },
        {
          value: 7,
          text: "高校生・高専生",
        },
        {
          value: 8,
          text: "大学生・大学院生・短大生・専門学校生",
        },
        {
          value: 9,
          text: "社会人",
        },
        {
          value: 10,
          text: "子どもはいない",
        },
      ],
    },
  ],
  queCount: 0,
};

const maritalStatusTemplate: QuestionTemplate = {
  templateId: "maritalStatus",
  templateName: "婚姻状況",
  editable: true,
  titleEditable: false,
  title: [
    {
      type: "paragraph",
      children: [{ text: "あなたの婚姻状況を教えてください。" }],
    },
  ],
  type: "S",
  choiceGroups: [
    {
      id: 1,
      choices: [
        { value: 1, text: "未婚" },
        { value: 2, text: "既婚" },
        { value: 3, text: "離別・死別" },
      ],
    },
  ],
  queCount: 0,
};

const jobTemplate: QuestionTemplate = {
  templateId: "job",
  templateName: "職業",
  editable: true,
  titleEditable: false,
  title: [
    {
      type: "paragraph",
      children: [{ text: "あなたの職業をお知らせください。" }],
    },
  ],
  type: "S",
  choiceGroups: [
    {
      id: 1,
      choices: [
        {
          value: 1,
          text: "会社役員・会社員・公務員",
        },
        {
          value: 2,
          text: "派遣社員",
        },
        {
          value: 3,
          text: "パート・アルバイト",
        },
        {
          value: 4,
          text: "自営業",
        },
        {
          value: 5,
          text: "自由業・フリーランス",
        },
        {
          value: 6,
          text: "高校生・高専生",
        },
        {
          value: 7,
          text: "大学（短大・専門学校）生・大学院生",
        },
        {
          value: 8,
          text: "専業主婦・主夫",
        },
        {
          value: 9,
          text: "無職（定年後退職を含む）・家事手伝い",
        },
        {
          value: 10,
          text: "その他",
          other: true,
        },
      ],
    },
  ],
  queCount: 0,
};

export const templates: QuestionTemplate[] = [
  { ...questionTemplate.gender, editable: true, titleEditable: false },
  { ...questionTemplate.age, editable: true, titleEditable: false },
  { ...questionTemplate.prefecture, editable: true, titleEditable: false },
  jobTemplate,
  maritalStatusTemplate,
  childrenTemplate,
];

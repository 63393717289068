import React, { FunctionComponent } from "react";

declare type EditorPageHeaderRow = typeof Row & {
	Label: typeof Label;
	Item: typeof Item;
};

const Row: FunctionComponent = React.memo(({ children }) => {
	return <div className="editor-page-header__row">{children}</div>;
});

const Label: FunctionComponent = React.memo(({ children }) => {
	return <div className="editor-page-header__row__label">{children}</div>;
});

const Item: FunctionComponent = React.memo(({ children }) => {
	return <div className="editor-page-header__row__item">{children}</div>;
});

const EditorPageHeaderRow: EditorPageHeaderRow = Row as EditorPageHeaderRow;

EditorPageHeaderRow.Label = Label;
EditorPageHeaderRow.Item = Item;

export default EditorPageHeaderRow;

import React, { FunctionComponent } from "react";

declare type EditorPageHeader = typeof Header & {
	Title: typeof Title;
};

const Header: FunctionComponent = React.memo(({ children }) => {
	return <div className="editor-page-header">{children}</div>;
});

const Title: FunctionComponent = React.memo(({ children }) => {
	return <h5 className="editor-page-header__title">{children}</h5>;
});

const EditorPageHeader: EditorPageHeader = Header as EditorPageHeader;

EditorPageHeader.Title = Title;

export default EditorPageHeader;

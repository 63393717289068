import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "aggregate":
			return {
				...state,
				aggregateJob: action.payload,
			};
		case "job":
			return {
				...state,
				job: action.payload,
			};
		case "message_info":
			return {
				...state,
				info: action.payload,
				progressState: "done",
			};
		case "progress_state":
			return {
				...state,
				progressState: action.payload,
			};
	}
};

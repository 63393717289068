import React, { ChangeEvent, FunctionComponent, useEffect, useRef } from "react";
import { Form, FormFile } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
	accept?: string;
	disabled?: boolean;
	feedback?: string;
	id: string;
	isInvalid?: boolean;
	value?: File;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FileInput: FunctionComponent<Props> = ({ accept, disabled, id, isInvalid, feedback, value, onChange }) => {
	const { t } = useTranslation();

	const ref = useRef<HTMLInputElement>();

	useEffect(() => {
		ref.current && accept && (ref.current.accept = accept);
	}, [accept, ref]);

	return (
		<Form.File id={id} custom className="bs-custom-file-input" readOnly={disabled}>
			<FormFile.Input ref={ref} isInvalid={isInvalid} onChange={onChange} />
			<FormFile.Label data-browse={t("file_input.choose_file")}>
				{value?.name ?? t("file_input.placeholder")}
			</FormFile.Label>
			<Form.Control.Feedback className="bs-custom-file-input__feedback" type="invalid">
				{feedback}
			</Form.Control.Feedback>
		</Form.File>
	);
};

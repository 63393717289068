import classNames from "classnames";
import React, { FunctionComponent, ReactElement } from "react";
import { ResultViewType } from "../../../../../../../types/result-view-type";
import { ValueCells } from "./value-cells";

type Props = {
	datas: { ns: any[][]; percents: any[][]; options?: any[][] };
	head?: ReactElement;
	index: number;
	selectedCols: Set<number>;
	selectedRows: Set<number>;
	tail?: ReactElement;
	type: ResultViewType;
	horizontal: boolean;
	onClickCell: (col: number, row: number) => void;
};

export const ValuesRows: FunctionComponent<Props> = React.memo(
	({ datas, head, index, selectedCols, selectedRows, horizontal, tail, type, onClickCell }) => {
		return (
			<>
				<tr
					className={classNames("cross__row", {
						"cross__row--selected": selectedRows.has(index * 2 + (type === "percent" ? 1 : 0)),
					})}
				>
					{head}
					<ValueCells
						datas={datas}
						index={index}
						indexOffset={type === "percent" ? 1 : 0}
						selectedCols={selectedCols}
						type={type}
						horizontal={horizontal}
						onClickCell={onClickCell}
					/>
					{tail}
				</tr>
				{type === "both" && (
					<tr
						className={classNames("cross__row", {
							"cross__row--selected": selectedRows.has(index * 2 + 1),
						})}
					>
						<ValueCells
							datas={datas}
							index={index}
							indexOffset={1}
							selectedCols={selectedCols}
							type="percent"
							horizontal={horizontal}
							onClickCell={onClickCell}
						/>
					</tr>
				)}
			</>
		);
	}
);

import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Container, ContainerProps } from "react-bootstrap";

export const PageContents: FunctionComponent<ContainerProps> = ({ children, className, ...props }) => {
	return (
		<Container className={classNames("page-contents", className)} {...props}>
			{children}
		</Container>
	);
};

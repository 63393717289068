import React, { FunctionComponent } from "react";
import { OptionRow } from "./option-row";

type Props = {
	indexOffset: number;
	labels: string[];
	options: any[][];
	selectedCols: Set<number>;
	selectedRows: Set<number>;
	onClickCell: (col: number, row: number) => void;
	onClickRowHeader: (rows: number[]) => void;
};

export const OptionsArea: FunctionComponent<Props> = React.memo(({ options, labels, ...props }) => {
	return (
		<>
			{labels.map((label, i) => (
				<OptionRow key={i} index={i} label={label} options={options[i]} {...props} />
			))}
			/
		</>
	);
});

import classNames from "classnames";
import React, { FunctionComponent, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { InfoPage } from "../components/pages/info";
import { NavBar } from "../components/parts/nav-bar";
import { AggregatesContainer } from "../containers/aggregates";
import { CrossResultContainer } from "../containers/aggregates/cross-result";
import { AggregateEditorContainer } from "../containers/aggregates/editor";
import { SyncRawdataContainer } from "../containers/aggregates/syncRawdata";
import { UploadRawdataContainer } from "../containers/aggregates/uploadRawdata";
import { WithAuth } from "../containers/auth";
import { EnqueteEditorContainer } from "../containers/enquete-editor";
import { Main } from "../containers/main";
import { ContentsState } from "../types/contents-state";
import { endpoint } from "./endpoints";
import { reducer } from "./reducer";
import { initState } from "./state";

export const Routes: FunctionComponent = () => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(reducer, initState);

	const login = useCallback((payload: ContentsState<number>) => {
		dispatch({ type: "media_id", payload });
	}, []);

	return (
		<BrowserRouter>
			<div className={classNames("body-container", { "body-container--show-modal": state.modalShow })}>
				<NavBar />
				<Main>
					<Switch>
						<Route
							path={
								process.env.ENV === "development"
									? [endpoint.createEnquete, endpoint.editEnqueteId]
									: endpoint.editEnqueteId
							}
							exact
						>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<EnqueteEditorContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.aggregates}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<AggregatesContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.createAggregate}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<AggregateEditorContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.editAggregateId}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<AggregateEditorContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.crossResultId}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<CrossResultContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.syncRawdataId}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<SyncRawdataContainer />
							</WithAuth>
						</Route>
						<Route path={`/:jobId/${endpoint.uploadRawdataId}`} exact>
							<WithAuth mediaId={state.mediaId} onLogin={login}>
								<UploadRawdataContainer />
							</WithAuth>
						</Route>
						<Route path={endpoint.error} exact>
							<InfoPage />
						</Route>
						<Route>
							<ErrorPage text={t("not_found.text")} title={t("page_title.not_found")} />
						</Route>
					</Switch>
				</Main>
			</div>
		</BrowserRouter>
	);
};

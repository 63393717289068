import React, { FunctionComponent } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import NB from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

export type Props = {};

export const NavBar: FunctionComponent<Props> = React.memo(() => {
	const { t } = useTranslation();
	return (
		<NB className="nav-bar" bg="primary" expand="sm" variant="dark">
			<NB.Brand className="nav-bar__brand">
				<img className="nav-bar__brand__image" alt="supcolo" src="/images/supcolo.svg" />
			</NB.Brand>
			<Nav className="nav-bar__right-menu">
				<Button href={process.env.SUPPORT_SUPCOLO_URL} target="_blaknk" variant="primary">
					{t("nav_bar.faq")}
				</Button>
			</Nav>
		</NB>
	);
});

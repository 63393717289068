import React, { useCallback, useEffect, useRef, useState } from "react";
import { Crosses } from "../../../../lib/aggregate/converter";
import { ResultViewType } from "../../../../types/result-view-type";
import { VariableSizeList } from "react-window";
import { CrossArea } from "./cross-area";

export type CrossViewProps = {
	crosses?: Crosses;
	horizontal: boolean;
	viewType: ResultViewType;
};

export const CrossView = React.memo<CrossViewProps>(({ crosses, horizontal, viewType: view }) => {
	const ref = useRef<HTMLDivElement>(null);
	const listRef = useRef<VariableSizeList>(null);

	const [rect, setRect] = useState<DOMRect | undefined>();

	useEffect(() => {
		if (!ref.current) return;

		setRect(ref.current.getBoundingClientRect());
		window.onresize = () => {
			setRect(ref.current.getBoundingClientRect());
		};
	}, [ref.current]);

	useEffect(() => {
		if (!listRef.current) return;
		listRef.current.resetAfterIndex(0);
	}, [listRef, horizontal, view]);

	const getHeight = useCallback(
		(index: number) => {
			// 1em = 12px;
			const em = 12;
			// padding = 0.1rem;
			const padding = em * 0.2;
			// line-hight = 1.5;
			const lineHeight = 1.5;
			// border-width = 1px;
			const border = 1;
			// cross-areaのpadding
			// padding-top: 1rem;
			// padding-bottom
			const areaPadding = em * 3;
			// title
			// font-size = 1.2em;
			// line-height = 2;
			const titleHeight = em * 1.2 * 2;
			// 一行あたりの文字数
			const maxLineLettersCount = rect?.width ? Math.ceil((rect.width - em * 4) / (em * 1.2)) : 0;

			const { bigTitle, condition, labels, options, qunename, selections, title } = crosses[index];

			// (horizontal ? 最長のselection : 最長のlabel) / 4で行数を取得
			const headerRowsCount = horizontal
				? Math.max(...selections.map((selection) => Math.ceil(selection.length / 4)))
				: labels.reduce((accumulator, labels) => {
						return (
							accumulator +
							Math.max(...labels.map(({ label, colSpan = 1 }) => Math.ceil(label.length / colSpan / 4)), 1)
						);
				  }, 0);

			// 行あたりの定数
			const cellConstant = padding + border;
			const rowConstant = em * lineHeight;

			const defaultHeight = view === "both" ? 2 * rowConstant + cellConstant : rowConstant;

			const valuesHeight = horizontal
				? Math.max(
						...labels.map((labels) => {
							return labels.reduce((accumulator, { label, colSpan = 1 }) => {
								return (
									accumulator +
									Math.max(colSpan * rowConstant, Math.ceil(label.length / 4) * rowConstant, defaultHeight) +
									cellConstant
								);
							}, defaultHeight + cellConstant);
						})
				  )
				: selections.reduce((accumulator, selection) => {
						return accumulator + Math.max(Math.ceil(selection.length / 14) * rowConstant, defaultHeight) + cellConstant;
				  }, 0);

			// optionsの高さ horizontal時は0
			const optionsHeight = horizontal
				? 0
				: options.reduce((accumulator, option) => {
						return accumulator + Math.max(Math.ceil(option.length / 14), 1);
				  }, 0) *
						rowConstant +
				  options.length * cellConstant;

			const bodyHeight = border + valuesHeight + optionsHeight;
			const headerHeight = headerRowsCount * rowConstant + (horizontal ? cellConstant : labels.length * cellConstant);

			// + 1はheader
			// 最後の + borderは最下部のborder
			return (
				titleHeight *
					(Math.ceil(`${qunename}.${title}`.length / maxLineLettersCount) +
						(bigTitle ? (maxLineLettersCount ? Math.ceil(bigTitle.length / maxLineLettersCount) : 1) : 0) +
						(condition
							? maxLineLettersCount
								? Math.ceil(/*`【${condition}】`.length*/ (condition.length + 2) / maxLineLettersCount)
								: 1
							: 0)) +
				bodyHeight +
				headerHeight +
				border +
				areaPadding
			);
		},
		[crosses, rect, horizontal, view]
	);

	return (
		<div ref={ref} className="cross-view">
			{crosses.length > 1 ? (
				<VariableSizeList
					ref={listRef}
					height={rect?.height ?? 0}
					itemCount={crosses.length}
					itemSize={getHeight}
					width="auto"
				>
					{({ index, style }) => (
						<CrossArea
							key={`cross-${crosses[index].qunename}${crosses[index].condition}`}
							cross={crosses[index]}
							style={style}
							horizontal={horizontal}
							type={view}
						/>
					)}
				</VariableSizeList>
			) : (
				!!crosses &&
				!!crosses.length && (
					<CrossArea
						key={`cross-${crosses[0].qunename}${crosses[0].condition}`}
						cross={crosses[0]}
						horizontal={horizontal}
						type={view}
					/>
				)
			)}
		</div>
	);
});

import classNames from "classnames";
import React, { FunctionComponent, useEffect, useReducer, useRef } from "react";
import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	delay?: number;
	onClose?: () => void;
};

export const MessageToast: FunctionComponent<Props> = React.memo(({ children, delay = 6000, onClose }) => {
	const ref = useRef<HTMLDivElement>(null);

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		if (children != null) {
			dispatch({ type: "bufferedChildren", payload: children });

			requestAnimationFrame(() => {
				const payload = ref.current.scrollHeight;
				dispatch({ type: "height", payload });

				onClose != null &&
					setTimeout(() => {
						dispatch({ type: "height", payload: 0 });
					}, delay);
			});
		}
	}, [children]);

	useEffect(() => {
		if (state.height === 0) onClose?.();
	}, [state.height, onClose]);

	return (
		<div
			className={classNames("message-toast", "bg-gray", { "message-toast--show": children != null })}
			style={{ height: `${state.height}px` }}
		>
			<div ref={ref} className="message-toast__body">
				{state.bufferedChildren}
			</div>
		</div>
	);
});

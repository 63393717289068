import React, { FunctionComponent, useCallback, useMemo } from "react";
import { ResultViewType } from "../../../../../../../types/result-view-type";

type Props = {
	index: number;
	selection: string;
	type: ResultViewType;
	onClickRowHeader: (rows: number[]) => void;
};

export const SelectionCell: FunctionComponent<Props> = React.memo(({ index, selection, type, onClickRowHeader }) => {
	const rowSpan = useMemo(() => (type === "both" ? 2 : 1), [type]);

	const handleClickRowHeader = useCallback(
		() => onClickRowHeader([index * 2, index * 2 + 1]),
		[index, onClickRowHeader]
	);
	return (
		<td className="cross__selection" rowSpan={rowSpan} onClick={handleClickRowHeader}>
			{selection}
		</td>
	);
});

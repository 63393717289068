import { Action } from "./route";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "media_id":
			return { ...state, mediaId: action.payload };
		case "modal_show":
			return { ...state, modalShow: action.payload };
		case "reloading":
			return { ...state, reloading: action.payload };
	}
};

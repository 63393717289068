import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { importType } from "../../../../../server/repositories/aggregate-job/model";
import { internalApiEndpoint } from "../../../../../server/router/api/internal/endpoint";
import { GetAggregateJobListResponse } from "../../../../../server/types/request/internal/aggregate";
import { makeError, get } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";
import { GearButton } from "../../parts/buttons";
import { ButtonsContainer } from "../../parts/buttons-container";
import { ErrorsViewer } from "../../parts/errors-viewer";
import { LoadingSpinner } from "../../parts/loadings";
import Page from "../../parts/page";
import { PagingTable } from "../../parts/paging-table";
import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	jobId: string;
};

export const AggregatesPage: FunctionComponent<Props> = React.memo(({ jobId }) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(reducer, initState);

	const handleGet = useCallback(
		(page: number) => {
			get<GetAggregateJobListResponse>(`${internalApiEndpoint.aggregateJobs}/${jobId}`, { page, limit: 10 })
				.then((res) => {
					dispatch({ type: "aggregates", payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: "aggregates", payload: { state: "failed", info: makeError(error) } });
				});
		},
		[jobId]
	);

	useEffect(() => handleGet(0), [handleGet]);

	const header = useMemo(
		() => [
			{ label: t("aggregate.aggregate_no"), value: "aggregate_no" },
			{ label: t("aggregate.name"), value: "name" },
			{ label: t("aggregate.import_type"), value: "importType" },
			{ label: t("aggregate.upload_datetime"), value: "uploadDatetime" },
			{ label: t("aggregate.created_at"), value: "createdAt" },
			{ label: "", value: "dropdown" },
		],
		[]
	);

	const items = useMemo(() => {
		if (state.aggregates.state !== "loaded") return [];

		return state.aggregates.value.rows.map(
			({ _id, createdAt, import_type: _importType, job_id: jobId, uploadDateTime = "", ...aggregate }) => ({
				...aggregate,
				importType: importType[_importType],
				uploadDatetime: uploadDateTime.length > 0 ? new Date(uploadDateTime).toLocaleString() : "",
				createdAt: new Date(createdAt).toLocaleString(),
				dropdown: (
					<Dropdown>
						<Dropdown.Toggle as={GearButton} />
						<Dropdown.Menu>
							<Dropdown.Item as={Link} to={`/${jobId}/${endpoint.editAggregate}/${_id}`}>
								{t("aggregate.menu.edit")}
							</Dropdown.Item>
							{_importType === "manual" ? (
								<Dropdown.Item as={Link} to={`/${jobId}/${endpoint.uploadRawdata}/${_id}`}>
									{t("aggregate.menu.upload_rawdata")}
								</Dropdown.Item>
							) : (
								<Dropdown.Item as={Link} to={`/${jobId}/${endpoint.syncRawdata}/${_id}`}>
									{t("aggregate.menu.sync_rawdata")}
								</Dropdown.Item>
							)}
							<Dropdown.Item
								as={Link}
								disabled={uploadDateTime.length === 0}
								to={`/${jobId}/${endpoint.crossResult}/${_id}`}
							>
								{t("aggregate.menu.view_cross_result")}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				),
			})
		);
	}, [jobId, state.aggregates]);

	return (
		<Page className="aggregates-page" fluid>
			<Page.Header title={t("page_title.aggregates")}>
				<ButtonsContainer wide>
					<Button as={Link} to={`/${jobId}/${endpoint.createAggregate}`} variant="secondary">
						{t("aggregates_page.create_aggregate")}
					</Button>
				</ButtonsContainer>
			</Page.Header>
			<Page.Contents fluid>
				<div className="aggregates-page__message">
					<p className="aggregates-page__message__paragraph">
						{t("aggregates_page.message1")}
						<span className="text-danger">{t("aggregates_page.message2")}</span>
						{t("aggregates_page.message3")}
					</p>
					<p className="aggregates-page__message__paragraph">{t("aggregates_page.message4")}</p>
				</div>
				{state.aggregates.state === "loaded" ? (
					<PagingTable
						className="aggregates-page__table"
						header={header}
						items={items}
						page={state.aggregates.value.page}
						pageCount={state.aggregates.value.pages}
						onGet={handleGet}
					>
						<colgroup>
							<col className="aggregates-page__table__col__no" />
							<col className="aggregates-page__table__col__name" />
							<col className="aggregates-page__table__col__import-type" />
							<col className="aggregates-page__table__col__upload-datetime" />
							<col className="aggregates-page__table__col__created-at" />
							<col className="aggregates-page__table__col__dropdown" />
						</colgroup>
					</PagingTable>
				) : state.aggregates.state === "failed" ? (
					<ErrorsViewer info={state.aggregates.info} />
				) : (
					<LoadingSpinner />
				)}
			</Page.Contents>
		</Page>
	);
});

import React, { FunctionComponent } from "react";
import { AxisLabel } from "../../../../../../lib/aggregate/axis";
import { LabelCells } from "./label-cells";
import { OptionCell } from "./option-cell";
import { SelectionCell } from "./selection-cell";

type Props = {
	labels: AxisLabel[][];
	options: string[];
	selectedCols: Set<number>;
	selections: string[];
	horizontal: boolean;
	onClickColHeader: (cols: number[]) => void;
};

export const HeaderArea: FunctionComponent<Props> = React.memo(
	({ labels, options, selectedCols, selections, horizontal, onClickColHeader }) => {
		return (
			<thead>
				{/* 軸名称 */}
				{horizontal ? (
					<tr>
						<th colSpan={horizontal ? labels.length : 1} />
						{selections.map((selection, i) => (
							<SelectionCell
								key={i}
								index={i}
								selectedCols={selectedCols}
								selection={selection}
								onClick={onClickColHeader}
							/>
						))}
						{options != null &&
							options.map((option, i) => (
								<OptionCell
									key={i}
									index={i + selections.length}
									option={option}
									selectedCols={selectedCols}
									onClick={onClickColHeader}
								/>
							))}
					</tr>
				) : (
					<>
						{labels.map((_labels, i) => (
							<LabelCells
								key={`axis-label-${i}`}
								index={i}
								labels={_labels}
								rowSpan={labels.length}
								selectedCols={selectedCols}
								onClickColHeader={onClickColHeader}
							/>
						))}
					</>
				)}
			</thead>
		);
	}
);

import { AggregationValue } from "@lu/muscat-analytics-library/dist/model/cross";

export class CrossAggregateBase {
	/**
	 * 最下部の合計値を取得する。
	 * @param {AggregationValue} aggregateValue
	 * @returns {{ total: number; noWeightTotal: number }[]}
	 */
	protected getBottomLayerTotal(aggregateValue: AggregationValue): { total: number; noWeightTotal: number }[] {
		if (aggregateValue.grouping.every((g) => !g.axis)) {
			return aggregateValue.grouping.map((g) => ({ total: g.total, noWeightTotal: g.noWeightTotal }));
		}
		return aggregateValue.grouping.reduce((a, b) => {
			return [...a, ...this.getBottomLayerTotal(b.axis)];
		}, []);
	}
	/**
	 * 最下部の平均値を取得する。
	 * @param {AggregationValue} aggregateValue
	 * @returns {{ total: number; noWeightTotal: number }[]}
	 */
	protected getBottomAverage(aggregateValue: AggregationValue): number[] {
		if (aggregateValue.grouping.every((g) => !g.axis)) {
			return aggregateValue.grouping.map((g) => g.average).filter((ave) => ave !== undefined);
		}
		return aggregateValue.grouping.reduce((a, b) => [...a, ...this.getBottomAverage(b.axis)], []);
	}
	/**
	 * 最下部の加重平均値を取得する。
	 * @param {AggregationValue} aggregateValue
	 * @returns {{ total: number; noWeightTotal: number }[]}
	 */
	protected getBottomWeightedAverage(aggregateValue: AggregationValue): number[] {
		if (aggregateValue.grouping.every((g) => !g.axis)) {
			return aggregateValue.grouping.map((g) => g.weightedAverage).filter((ave) => ave !== undefined);
		}
		return aggregateValue.grouping.reduce((a, b) => [...a, ...this.getBottomWeightedAverage(b.axis)], []);
	}
	/**
	 * 最下部の標準偏差を取得する。
	 * @param {AggregationValue} aggregateValue
	 * @returns {{ total: number; noWeightTotal: number }[]}
	 */
	protected getBottomStandardDeviation(aggregateValue: AggregationValue): number[] {
		if (aggregateValue.grouping.every((g) => !g.axis)) {
			return aggregateValue.grouping
				.map((g) => g.standardDeviation)
				.filter((standardDeviation) => standardDeviation !== undefined);
		}
		return aggregateValue.grouping.reduce((a, b) => [...a, ...this.getBottomStandardDeviation(b.axis)], []);
	}
}

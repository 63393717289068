import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "cross":
			return {
				...state,
				cross: action.payload,
			};
		case "data":
			return {
				...state,
				data: action.payload,
			};
		case "horizontal":
			return {
				...state,
				horizontal: action.payload,
			};
		case "job":
			return {
				...state,
				job: action.payload,
			};
		case "progress_state":
			return {
				...state,
				progressState: action.payload,
			};
		case "view_type":
			return {
				...state,
				viewType: action.payload,
			};
	}
};

import { ErrorObject } from "@lu/validator";

export const getErrorObject = (keys: string[], errors?: ErrorObject): ErrorObject | undefined => {
	const error = getError(keys, errors);

	return typeof error !== "string" ? error : undefined;
};

export const getErrorText = (keys: string[], errors?: ErrorObject): string | undefined => {
	const error = getError(keys, errors);

	return typeof error === "string" ? error : undefined;
};

const getError = (keys: string[], errors?: ErrorObject): ErrorObject | string | undefined => {
	return keys.reduce(
		(accumulator, key) => (accumulator && typeof accumulator !== "string" ? accumulator[key] : accumulator),
		errors
	);
};

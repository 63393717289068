import { ErrorObject } from "@lu/validator";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { MessageInfo } from "../../../types/info";
import { ChildErrorsView } from "./chile-errors-viewer";

type Props = { arrayKeys?: string[]; info?: MessageInfo; errors?: string | ErrorObject; objectName?: string };

export const ErrorsViewer: FunctionComponent<Props> = React.memo(
	({ arrayKeys, info, errors, objectName = "errors_viewer" }) => {
		const ref = useRef(null);

		const arrayKeysSet = useMemo(() => new Set(arrayKeys), [arrayKeys]);

		useEffect(() => {
			if (ref && ref.current) {
				const height = ref.current.scrollHeight;

				ref.current.style.height = 0;

				requestAnimationFrame(() => {
					ref.current.style.height = height ? `${height}px` : "auto";
				});
			}
		}, [ref, info]);

		const transitionEnd = useCallback(() => {
			if (ref && ref.current) {
				ref.current.style.height = "auto";
			}
		}, [ref, info]);

		errors = useMemo(() => (!errors && info ? info.errors : errors), [errors, info]);

		const message = useMemo(() => info && !info.isSuccess && info.message, [info]);

		return (
			<div ref={ref} className="errors-viewer" onTransitionEnd={transitionEnd}>
				<Alert show={(!!info && !info.isSuccess) || !!errors} variant="danger">
					{message && <p>{message}</p>}
					<>
						{errors && typeof errors !== "string" && (
							<ChildErrorsView arrayKeysSet={arrayKeysSet} errors={errors} objectName={objectName} />
						)}
					</>
				</Alert>
			</div>
		);
	}
);

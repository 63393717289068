import {
	AggregationQuestion,
	AggregationQuestionItem,
	AggregationValue,
	CrossData,
} from "@lu/muscat-analytics-library";
import { AxisLabelConveter, AxisLabel, AxisFormat } from "./axis";
import { CrossAggregateBase } from "./base";
import { isDefined, makePercent, makeSampleSize, round } from "./fun";

export type CrossObj = {
	bigTitle?: string;
	condition?: string;
	title: string;
	qunename: string;
	labels: AxisLabel[][];
	selections: string[];
	options?: string[];
	datas: { ns: any[][]; percents: any[][]; options?: any[][] }; // 集計エリアの内容
};

export type Crosses = CrossObj[];

export class CrossAggregateConverter extends CrossAggregateBase {
	protected labels: AxisLabel[][];
	private crosses: Crosses = [];
	private index = 0;
	private axisFormat: AxisFormat;
	private initIndex() {
		this.index = 0;
	}
	private next(): void {
		this.index++;
	}
	constructor(axisFormat: AxisFormat) {
		super();
		this.axisFormat = axisFormat;
	}
	private setAxisLabel(crosses: CrossData[]): void {
		const axisLabelConveter = new AxisLabelConveter(this.axisFormat);
		this.labels = axisLabelConveter.make(crosses);
	}

	public static convert(crossDatas: CrossData[], axisFormat: AxisFormat): Crosses {
		const ins = new CrossAggregateConverter(axisFormat);
		return ins.convert(crossDatas);
	}
	public convert(crossDatas: CrossData[]): Crosses {
		this.setAxisLabel(crossDatas);
		for (const { /*label,*/ data } of crossDatas) {
			this.initIndex();
			for (const aggQuestion of data) {
				this.setQuestionBase(aggQuestion);
				const { items, totals } = aggQuestion;
				this.setTotals(items, totals);
				// this.setAxisLabel(label);
				this.setValues(items, totals);
				this.setOptionsValue(totals);
				this.next();
			}
		}
		return this.crosses;
	}
	/**
	 * 合計エリアの作成
	 * @param {AggregationQuestionItem[]} items 集計アイテム
	 * @param {AggregationValue} totals 集計母数
	 */
	protected setTotals(items: AggregationQuestionItem[], totals: AggregationValue): any[][] {
		if (this.crosses[this.index].datas.ns.length) return;
		const baseTotal = totals.total;
		const ns = [makeSampleSize(baseTotal)];
		const percents = [makePercent(baseTotal, baseTotal)];
		for (const item of items) {
			const total = item.aggregate.total;
			ns.push(makeSampleSize(total));
			percents.push(makePercent(total, baseTotal));
		}
		const options = [totals.average, totals.weightedAverage, totals.standardDeviation]
			.filter((optionValue) => isDefined(optionValue))
			.map((optionValue) => round(optionValue));
		this.crosses[this.index].datas.ns = ns.map((v) => [v]);
		this.crosses[this.index].datas.percents = percents.map((v) => [v]);
		if (options.length) this.crosses[this.index].datas.options = options.map((o) => [o]);
	}
	private pushValues(index: number, target: "ns" | "percents" | "options", values: any[]) {
		const datas = this.crosses[this.index].datas[target];
		if (!datas) this.crosses[this.index].datas[target] = [];
		const vs = this.crosses[this.index].datas[target][index];
		if (vs) return vs.push(...values);
		this.crosses[this.index].datas[target].push(values);
	}
	public setValues(items: AggregationQuestionItem[], totals: AggregationValue): void {
		const itemTotals = this.getBottomLayerTotal(totals);
		let index = 0;
		this.pushValues(
			index,
			"ns",
			itemTotals.map((x) => makeSampleSize(x.total))
		);
		this.pushValues(
			index,
			"percents",
			itemTotals.map((v) => (v.total ? 100 : "-"))
		);
		index++;
		for (const item of items) {
			const bottomValues = this.getBottomLayerTotal(item.aggregate);
			const vs = bottomValues.map((value, vIndex) => {
				const v = value.total;
				const total = itemTotals[vIndex].total;
				return { n: makeSampleSize(v), perecnt: makePercent(v, total) };
			});
			this.pushValues(
				index,
				"ns",
				vs.map((v) => v.n)
			);
			this.pushValues(
				index,
				"percents",
				vs.map((v) => v.perecnt)
			);
			index++;
		}
	}
	private setOptionsValue(totals: AggregationValue): void {
		const optionValues = [
			this.getBottomAverage(totals),
			this.getBottomWeightedAverage(totals),
			this.getBottomStandardDeviation(totals),
		].filter((v) => v.length);
		let index = 0;
		for (const values of optionValues) {
			if (!values && values.length === 0) continue;
			this.pushValues(
				index,
				"options",
				values.map((v) => round(v))
			);
			index++;
		}
	}
	/**
	 * 選択肢エリアの作成
	 * 　選択肢エリアの生成は管理している行を進めない。
	 * @param {AggregationQuestionItem[]} items
	 * @param {AggregationValue} totals
	 * @returns {string[]}
	 */
	private makeSelections(items: AggregationQuestionItem[]): string[] {
		return ["全体", ...items.map(({ label }) => label)];
	}
	private makeOptions(totals: AggregationValue): string[] {
		return [
			{ label: "平均", target: !!this.getBottomAverage(totals).length },
			{ label: "平均", target: !!this.getBottomWeightedAverage(totals).length },
			{ label: "標準偏差", target: !!this.getBottomStandardDeviation(totals).length },
		]
			.filter(({ target }) => target)
			.map(({ label }) => label);
	}
	private setQuestionBase(aggregateQuestion: AggregationQuestion) {
		if (this.crosses[this.index]) return;
		this.crosses[this.index] = {
			qunename: aggregateQuestion.quename,
			bigTitle: aggregateQuestion.bigTitle,
			condition: aggregateQuestion.condition,
			title: aggregateQuestion.label,
			labels: this.labels,
			options: this.makeOptions(aggregateQuestion.totals),
			selections: this.makeSelections(aggregateQuestion.items),
			datas: { ns: [], percents: [] },
		};
	}
}

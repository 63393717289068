import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";
import { ResultViewType } from "../../../../../../../../types/result-view-type";

type Props = {
	col: number;
	row: number;
	selectedCols: Set<number>;
	type: ResultViewType;
	onClick: (col: number, row: number) => void;
};

export const ValueCell: FunctionComponent<Props> = React.memo(({ children, col, row, selectedCols, onClick }) => {
	const handleClickCell = useCallback(() => onClick(col, row), [col, row, onClick]);

	return (
		<td
			className={classNames("cross__n", {
				"cross__col--selected": selectedCols.has(col),
			})}
			onClick={handleClickCell}
		>
			{children}
		</td>
	);
});

export const endpoint = {
	aggregates: "aggregates",
	createAggregate: "create_aggregate",
	createEnquete: "/create_enquete",
	crossResult: "cross_result",
	crossResultId: "cross_result/:id",
	editAggregate: "edit_aggregate",
	editAggregateId: "edit_aggregate/:id",
	editEnquete: "/edit_enquete",
	editEnqueteId: "/edit_enquete/:id",
	syncRawdata: "sync_rawdata",
	syncRawdataId: "sync_rawdata/:id",
	uploadRawdata: "upload_rawdata",
	uploadRawdataId: "upload_rawdata/:id",
	error: "/error",
	top: "/",
} as const;

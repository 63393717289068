import { DefaultSchema, Key } from "@lu/repository";
import { Enquete, EnqueteStatus } from "@lu/muscat-library";
import { Media } from "../media/model";

export const enqueteType = {
	"open": "オープン型アンケート",
	"media": "自社会員向けアンケート",
	"monitas": "自社会員以外向けアンケート",
	"monitas-survey": "モニタス専用アンケート",
} as const;

export type EnqueteType = keyof typeof enqueteType;
export const enqueteTypeValues = Object.keys(enqueteType) as EnqueteType[];

export const surveyType = {
	pre: "予備調査",
	main: "本調査",
	all: "予備調査＋本調査",
};
export type SurveyType = keyof typeof surveyType;
export const surveyTypeValues = Object.keys(surveyType) as SurveyType[];

export type Job = {
	enquete_id: string; // muscatのアンケートID
	media_id: number; // メディアのID
	survey_title: string; // 調査タイトル
	enquete_title: string; // アンケートタイトル
	type: EnqueteType; // アンケートタイプ
	survey_type?: SurveyType; // 調査タイプは初回はoptionalとなる
	status: EnqueteStatus; // アンケートステータス（同期をとる）

	// supcolo管理ID
	supcolo_id?: number;
} & DefaultSchema;

export type JobWithEnquete = Job &
	Omit<Enquete, "enqueteId" | "questionnaire" | "_id"> & {
		media: Media;
		url: string;
	};

import { ReactNode } from "react";

export type State = {
	bufferedChildren?: ReactNode;
	height: number;
};

export const initState: State = {
	height: 0,
};

import React, { Fragment, FunctionComponent, useMemo } from "react";
import { ResultViewType } from "../../../../../../../../types/result-view-type";
import { ValueCell } from "./value-cell";

type Props = {
	datas: { ns: any[][]; percents: any[][]; options?: any[][] };
	index: number;
	indexOffset?: number;
	selectedCols: Set<number>;
	type: ResultViewType;
	horizontal: boolean;
	onClickCell: (col: number, row: number) => void;
};

export const ValueCells: FunctionComponent<Props> = React.memo(
	({ datas, index, indexOffset = 0, type, horizontal, onClickCell, ...props }) => {
		const { ns, percents } = datas;

		const target = useMemo(() => {
			const target = type === "percent" ? percents : ns;
			if (horizontal) {
				return target.map((target) => target[index]);
			}
			return target[index];
		}, [index, ns, percents, type]);

		return (
			<>
				{target.map((value, i) => (
					<Fragment key={i}>
						<ValueCell col={i} row={index * 2 + indexOffset} type={type} onClick={onClickCell} {...props}>
							{value}
						</ValueCell>
					</Fragment>
				))}
			</>
		);
	}
);

import { AggregateJobWithConfig } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { ContentsState } from "../../../../types/contents-state";
import { MessageInfo } from "../../../../types/info";
import { ProgressState } from "../../../../types/progress-state";

export type State = {
	aggregateJob: ContentsState<Partial<AggregateJobWithConfig>>;
	file?: File;
	fileInvalid: boolean;
	info?: MessageInfo;
	job: ContentsState<Job>;
	progressState: ProgressState;
};

export const initState: State = {
	aggregateJob: { state: "loading" },
	job: { state: "loading" },
	fileInvalid: false,
	progressState: "none",
};

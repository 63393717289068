import React, { FunctionComponent } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
	text: string;
	title: string;
};
export const ErrorPage: FunctionComponent<Props> = React.memo(({ text, title }) => {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = `${title} - ${t("page_title.site_name")}`;
	}, [title]);

	return (
		<div className={"m-3"}>
			<h1>{text}</h1>
		</div>
	);
});

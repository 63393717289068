import bsCustomInputFile from "bs-custom-file-input";
import i18n from "i18next";
import "react-app-polyfill/ie11";
import "babel-polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";

import jaJson from "../locales/ja.json";
import { Routes } from "./routes";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";

// bsCostomInputFileの初期化
bsCustomInputFile.init();

// i18nextの初期化
i18n.use(initReactI18next).init({
	resources: {
		ja: { translation: jaJson },
	},
	lng: "ja",
	fallbackLng: "ja",
	interpolation: { escapeValue: false },
});

// Muscatの初期化
// MuscatApi.setBaseUrl("http://192.168.101.254:3000");

ReactDOM.render(<Routes />, document.getElementById("myApp"));

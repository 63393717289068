import React, { FunctionComponent, ReactNode } from "react";

type Props = {
	title: ReactNode;
};

export const PageHeader: FunctionComponent<Props> = ({ children, title }) => {
	return (
		<header className="page-header">
			<div className="page-header__title">
				<h4>{title}</h4>
			</div>
			{children}
		</header>
	);
};

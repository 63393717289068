import React, { FunctionComponent, useCallback } from "react";
import classNames from "classnames";

type Props = {
	col: number;
	option: any[];
	row: number;
	selectedCols: Set<number>;
	onClickCell: (col: number, row: number) => void;
};

export const OptionCell: FunctionComponent<Props> = React.memo(({ col, option, row, selectedCols, onClickCell }) => {
	const handleClickCell = useCallback(() => onClickCell(col, row), [col, row, onClickCell]);
	return (
		<td
			className={classNames("cross__n", {
				"cross__col--selected": selectedCols.has(col),
			})}
			onClick={handleClickCell}
		>
			{option}
		</td>
	);
});

import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { AggregatesPage } from "../../components/pages/aggregates";

export const AggregatesContainer: FunctionComponent = React.memo(() => {
	const { t } = useTranslation();

	const { jobId } = useParams<{ id: string; jobId: string }>();

	useEffect(() => {
		document.title = `${t("page_title.aggregates")} - ${t("page_title.site_name")}`;
	}, []);

	return <AggregatesPage jobId={jobId} />;
});

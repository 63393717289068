import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { SettingSelectionGroups, SettingSelectionGroupsProps } from "./selection-groups";
import { AggregateTarget, AggregateTargetProps } from "./target";
import { get, makeError, post, put } from "../../../../lib/request";
import {
	GetAggregateQuestion,
	GetAggregateResponse,
	UpsertAggregateResponse,
} from "../../../../../../server/types/request/internal/aggregate";
import { LoadingSpinner } from "../../../parts/loadings";
import { ErrorsViewer } from "../../../parts/errors-viewer";
import { TitledContainer } from "../../../parts/titled-container";
import { ButtonsContainer } from "../../../parts/buttons-container";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { getErrorObject, getErrorText } from "../../../../lib/error";
import { internalApiEndpoint } from "../../../../../../server/router/api/internal/endpoint";
import { ValidatableInput } from "../../../parts/validatable-input";
import Page from "../../../parts/page";
import EditorPageHeader from "../../../parts/editor-page-header";
import EditorPageBody from "../../../parts/editor-page-body";
import { GetJobOneResponse } from "../../../../../../server/types/request/internal/job";
import { enqueteType } from "../../../../../../server/repositories/job/model";
import EditorPageHeaderRow from "../../../parts/editor-page-header/row";
import { useHistory } from "react-router";
import { endpoint } from "../../../../routes/endpoints";
import { reducer } from "./reducer";
import { initState } from "./state";
import { importType } from "../../../../../../server/repositories/aggregate-job/model";
import { Link } from "react-router-dom";
import { isImportType } from "../../../../utils/typeCheckers";
import { ValidatableSelect } from "../../../parts/validatable-select";

type Props = {
	id?: string;
	jobId: string;
};

export const AggregateEditorPage: FunctionComponent<Props> = React.memo(({ id, jobId }) => {
	const { t } = useTranslation();

	const history = useHistory<{ message?: string; prev?: string }>();

	const type = useMemo(() => (id === undefined ? "create" : "edit"), [id]);

	const [state, dispatch] = useReducer(reducer, { jobId, type }, initState);

	const { aggregateJob, job, questions } = state;

	useEffect(() => {
		if (!id) return;
		get<GetAggregateResponse>(`${internalApiEndpoint.aggregateJob}/${id}`)
			.then((res) => {
				dispatch({
					type: "aggregate",
					payload: { state: "loaded", value: res.data.aggregateJob },
				});
			})
			.catch((error) => {
				dispatch({
					type: "aggregate",
					payload: { state: "failed", info: makeError(error) },
				});
			});
	}, [id]);

	useEffect(() => {
		get<GetAggregateQuestion>(`${internalApiEndpoint.aggregateQuestion}/${jobId}`)
			.then((res) => {
				dispatch({
					type: "questions",
					payload: { state: "loaded", value: res.data.questions },
				});
			})
			.catch((error) => {
				dispatch({
					type: "questions",
					payload: { state: "failed", info: makeError(error) },
				});
			});

		get<GetJobOneResponse>(`${internalApiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({
					type: "job",
					payload: { state: "loaded", value: res.data.job },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: "job",
					payload: { state: "failed", info: makeError(error) },
				});
			});
	}, [jobId]);

	const axisLength = useMemo(() => {
		if (aggregateJob.state !== "loaded" || aggregateJob.value.config?.axis == null) return 0;

		return aggregateJob.value.config.axis.flatMap((axis) => axis).length;
	}, [aggregateJob]);

	const importTypeOptions = useMemo(
		() => [
			{ label: importType.all, value: "all" },
			{ label: importType.completed, value: "completed" },
			{ label: importType.manual, value: "manual" },
		],
		[]
	);

	const nameIninitalized = useMemo(
		() =>
			aggregateJob.state !== "loaded" ||
			((aggregateJob.value.name ?? "") === "" && getErrorText(["name"], state.info?.errors) == null),
		[aggregateJob, state.info]
	);

	const selectionError = useMemo(() => getErrorObject(["selectionGroups"], state.info?.errors), [state.info]);

	const targetQuestionsInvalid = useMemo(
		() => getErrorText(["targetQuestions"], state.info?.errors) != null,
		[state.info]
	);

	const uploadDateTime = useMemo(() => {
		if (
			aggregateJob.state !== "loaded" ||
			aggregateJob.value.uploadDateTime == null ||
			aggregateJob.value.uploadDateTime.length === 0
		)
			return "";
		return new Date(aggregateJob.value.uploadDateTime).toLocaleString();
	}, [aggregateJob]);

	const handleAddSelectionGroup = useCallback(() => dispatch({ type: "addSelectionGroup" }), []);

	const handleChangeImportType = useCallback(
		(payload: unknown) => isImportType(payload) && dispatch({ type: "importType", payload }),
		[]
	);

	const handleChangeSelectionGroup = useCallback<SettingSelectionGroupsProps["onChange"]>(
		(targetName, name, value) =>
			dispatch({
				type: "changeSelectionGroup",
				payload: { targetName, name, value },
			}),
		[]
	);

	const handleChangeTargetQuestions = useCallback<AggregateTargetProps["onChangeTargetQuestions"]>(
		(payload) => dispatch({ type: "targetQuestions", payload }),
		[]
	);

	const handleChangeAxis = useCallback<AggregateTargetProps["onChangeAxis"]>(
		(payload) => dispatch({ type: "axis", payload }),
		[]
	);

	const handleDeleteSelectionGroup = useCallback<SettingSelectionGroupsProps["onDeleteSelectionGroup"]>(
		(payload) => dispatch({ type: "deleteSelectionGroup", payload }),
		[]
	);

	const handleDragEnd = useCallback((result: DropResult) => {
		const resultType = result.type.split(":");

		dispatch({
			type: "moveSelectionItem",
			payload: {
				targetIndex: parseInt(resultType[1]),
				sourceIndex: result.source.index,
				destinationIndex: result.destination.index,
			},
		});
		return;
	}, []);

	const handleInputName = useCallback((payload: string) => dispatch({ type: "name", payload }), []);

	const handleSubmit = useCallback(
		(redirect?: "syncRawdata" | "uploadRawdata" | "viewCross") => {
			if (state.aggregateJob.state !== "loaded") return;

			dispatch({ type: "progressState", payload: "progress" });

			(type === "create"
				? post<UpsertAggregateResponse>(`${internalApiEndpoint.aggregateJob}/${jobId}`, state.aggregateJob.value)
				: put<UpsertAggregateResponse>(`${internalApiEndpoint.aggregateJob}/${id}`, state.aggregateJob.value)
			)
				.then((res) => {
					dispatch({
						type: "saveAggregate",
						payload: {
							aggregateJob: { state: "loaded", value: res.data.aggregateJob },
							info: {
								isSuccess: true,
								message: t("aggregate_editor_page.save_message"),
							},
						},
					});

					const { _id: id, job_id: jobId } = res.data.aggregateJob;

					const url =
						redirect === "syncRawdata"
							? `/${jobId}/${endpoint.syncRawdata}/${id}`
							: redirect === "uploadRawdata"
							? `/${jobId}/${endpoint.uploadRawdata}/${id}`
							: redirect === "viewCross"
							? `/${jobId}/${endpoint.crossResult}/${id}`
							: `/${jobId}/${endpoint.aggregates}`;

					history.push(url, {
						message: t("alert_message.saved"),
						prev: history.location.pathname,
					});
				})
				.catch((error) => dispatch({ type: "messageInfo", payload: makeError(error) }));
		},
		[id, jobId, state.aggregateJob, type]
	);

	const handleClickCancel = useCallback(
		() =>
			history.push(history.location.state?.prev ?? `/${jobId}/${endpoint.aggregates}`, {
				message: t("alert_message.canceled"),
			}),
		[jobId]
	);

	const handleClickSave = useCallback(() => handleSubmit(), [handleSubmit]);

	const handleClickSyncRawdata = useCallback(() => handleSubmit("syncRawdata"), [handleSubmit]);

	const handleClickViewCross = useCallback(() => handleSubmit("viewCross"), [handleSubmit]);

	const handleClickUploadRawdata = useCallback(() => handleSubmit("uploadRawdata"), [handleSubmit]);

	return (
		<Page className="aggregate-editor-page" fluid>
			<Page.Header title={t(`page_title.${type}_aggregate`)}>
				<ButtonsContainer>
					<Dropdown>
						<Dropdown.Toggle
							disabled={state.progressState === "progress" || uploadDateTime.length === 0}
							variant="outline-gray"
						>
							{t("aggregate_editor_page.view_aggregates")}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={handleClickViewCross}>{t("aggregate_editor_page.cross_result")}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Button
						disabled={state.progressState === "progress" || axisLength === 0}
						variant="secondary"
						onClick={handleClickSave}
					>
						{t("aggregate_editor_page.save")}
					</Button>
				</ButtonsContainer>
			</Page.Header>
			<Page.Contents className="aggregate-editor-page__contents">
				<ButtonsContainer className="aggregate-editor-page__buttons-container">
					<Button as={Link} to={`/${jobId}/${endpoint.aggregates}`} variant="white">
						<i className="bi-chevron-left" />
						<span>{t("aggregate_editor_page.go_back")}</span>
					</Button>
				</ButtonsContainer>
				{aggregateJob.state === "loaded" && job.state === "loaded" && questions.state === "loaded" && (
					<>
						<ErrorsViewer arrayKeys={["grouping", "selectionGroups"]} info={state.info} objectName="aggregate" />
						<EditorPageHeader>
							<EditorPageHeader.Title>{t("aggregate_editor_page.job_details")}</EditorPageHeader.Title>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_title")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{job.value.enquete_title}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
							<EditorPageHeaderRow>
								<EditorPageHeaderRow.Label>{t("job.enquete_type")}</EditorPageHeaderRow.Label>
								<EditorPageHeaderRow.Item>{enqueteType[job.value.type]}</EditorPageHeaderRow.Item>
							</EditorPageHeaderRow>
						</EditorPageHeader>
						<EditorPageBody>
							<EditorPageBody.Title>{t("aggregate_editor_page.config")}</EditorPageBody.Title>
							<TitledContainer title={t("aggregate.name")}>
								<ValidatableInput
									initialized={nameIninitalized}
									required
									value={aggregateJob.value.name}
									onInput={handleInputName}
								/>
							</TitledContainer>
							<TitledContainer className="aggregate-editor-page__titled-container" title={t("aggregate.import_type")}>
								<ValidatableSelect
									options={importTypeOptions}
									value={aggregateJob.value.import_type}
									onChange={handleChangeImportType}
								/>
								<div className="aggregate-editor-page__titled-container__value">
									<div className="aggregate-editor-page__titled-container__value__label">
										{t("aggregate_editor_page.upload_datetime")}
									</div>
									<div>{uploadDateTime}</div>
								</div>
								<ButtonsContainer>
									{aggregateJob.value.import_type !== "manual" && (
										<Button
											disabled={state.progressState === "progress" || axisLength === 0}
											variant="secondary"
											onClick={handleClickSyncRawdata}
										>
											{t("aggregate_editor_page.sync_rawdata")}
										</Button>
									)}
									<Button
										disabled={
											aggregateJob.value.import_type !== "manual" ||
											state.progressState === "progress" ||
											axisLength === 0
										}
										variant="secondary"
										onClick={handleClickUploadRawdata}
									>
										{t("aggregate_editor_page.upload_rawdata")}
									</Button>
								</ButtonsContainer>
							</TitledContainer>
							<TitledContainer title={t("aggregate_editor_page.selection_group")}>
								<DragDropContext onDragEnd={handleDragEnd}>
									<SettingSelectionGroups
										error={selectionError}
										selectionGroups={aggregateJob.value.config?.selectionGroups ?? []}
										questions={questions.value}
										onAddSelectionGroup={handleAddSelectionGroup}
										onChange={handleChangeSelectionGroup}
										onDeleteSelectionGroup={handleDeleteSelectionGroup}
									/>
								</DragDropContext>
							</TitledContainer>
							<TitledContainer title={t("aggregate_editor_page.target_select")}>
								<AggregateTarget
									questions={questions.value}
									selectionGroups={aggregateJob.value.config?.selectionGroups ?? []}
									targetQuestions={aggregateJob.value.config?.targetQuestions ?? []}
									axis={axisLength > 0 ? aggregateJob.value.config?.axis ?? [[]] : [[]]}
									invalid={targetQuestionsInvalid}
									onChangeTargetQuestions={handleChangeTargetQuestions}
									onChangeAxis={handleChangeAxis}
								/>
							</TitledContainer>
						</EditorPageBody>
						<ButtonsContainer className="aggregate-editor-page__footer-container" align="center">
							<Button variant="gray" onClick={handleClickCancel}>
								{t("upload_rawdata_page.cancel")}
							</Button>
							<Dropdown>
								<Dropdown.Toggle
									disabled={state.progressState === "progress" || uploadDateTime.length === 0}
									variant="outline-gray"
								>
									{t("aggregate_editor_page.view_aggregates")}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item disabled={axisLength === 0} onClick={handleClickViewCross}>
										{t("aggregate_editor_page.cross_result")}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Button disabled={axisLength === 0} variant="secondary" onClick={handleClickSave}>
								{t("aggregate_editor_page.save")}
							</Button>
						</ButtonsContainer>
					</>
				)}
				{(aggregateJob.state === "loading" || job.state === "loading" || questions.state === "loading") && (
					<LoadingSpinner />
				)}
				{aggregateJob.state === "failed" && <ErrorsViewer info={aggregateJob.info} />}
			</Page.Contents>
		</Page>
	);
});

import React, { FunctionComponent, useCallback, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router";
import { MessageToast } from "../../components/parts/message-toast";

export const Main: FunctionComponent = ({ children }) => {
	const { pathname } = useLocation();

	const history = useHistory<{ message?: string }>();

	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		ref.current?.scrollTo(0, 0);
	}, [pathname, ref]);

	const handleClose = useCallback(() => {
		history.replace(pathname);
	}, [pathname]);

	return (
		<main ref={ref}>
			<MessageToast onClose={handleClose}>{history.location.state?.message}</MessageToast>
			{children}
		</main>
	);
};

import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CrossResultPage } from "../../../components/pages/aggregates/cross-result";

export const CrossResultContainer: FunctionComponent = React.memo(() => {
	const { t } = useTranslation();

	const { id, jobId } = useParams<{ id: string; jobId: string }>();

	useEffect(() => {
		document.title = `${t("page_title.cross_result")} - ${t("page_title.site_name")}`;
	}, []);

	return <CrossResultPage id={id} jobId={jobId} />;
});

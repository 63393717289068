import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";
import { AxisLabel } from "../../../../../../../../lib/aggregate/axis";
import { ResultViewType } from "../../../../../../../../types/result-view-type";
import { hasOneOf } from "../../../lib";

type Props = {
	axisLabel: AxisLabel;
	index: number;
	selectedRows: Set<number>;
	type: ResultViewType;
	onClickRowHeader: (rows: number[]) => void;
};

export const LabelCell: FunctionComponent<Props> = React.memo(({ axisLabel, selectedRows, type, onClickRowHeader }) => {
	const { colSpan: _rowSpan = 1, rowSpan: _colSpan, indexes, label } = axisLabel;

	const handleClick = useCallback(() => onClickRowHeader(indexes), [indexes, onClickRowHeader]);

	return (
		<>
			<td
				className={classNames("cross__label", { "cross__row--selected": hasOneOf(selectedRows, indexes) })}
				colSpan={_colSpan}
				rowSpan={_rowSpan * (type === "both" ? 2 : 1)}
				onClick={handleClick}
			>
				{label}
			</td>
		</>
	);
});

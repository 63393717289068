import classNames from "classnames";
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

type Props = {
	className?: string;
	disabled?: boolean;
	id: string;
	tooltipText: string;
	value: any;
	values: { value: any; label: ReactNode }[];
	variant?: string;
	onChange?: (value: any, event: React.MouseEvent<HTMLElement>) => void;
};

export const ToggleLabelButton: FunctionComponent<Props> = React.memo(
	({ className, disabled, id, tooltipText, value, values, variant, onChange }) => {
		const activeIndex = useMemo(() => values.findIndex((v) => v.value === value), [values, value]);

		const renderToolTip = useCallback(
			(props) =>
				disabled ? null : (
					<Tooltip id={`${id}-tooltip`} {...props}>
						{tooltipText}
					</Tooltip>
				),
			[disabled, id, tooltipText]
		);

		const handleClick = useCallback(
			(event: React.MouseEvent<HTMLElement>) => {
				const rotatedIndex = activeIndex + 1 >= values.length ? 0 : activeIndex + 1;
				onChange?.(values[rotatedIndex].value, event);
			},
			[activeIndex, onChange]
		);

		return (
			<OverlayTrigger overlay={renderToolTip} placement="bottom">
				<Button
					className={classNames("MuBlEdToggleLabelButton", className)}
					disabled={disabled}
					variant={variant}
					onClick={handleClick}
				>
					{values[activeIndex].label}
				</Button>
			</OverlayTrigger>
		);
	}
);

import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Container, ContainerProps } from "react-bootstrap";
import { PageContents } from "./contents";
import { PageFooter } from "./footer";
import { PageHeader } from "./header";

const PageNode: FunctionComponent<ContainerProps> = ({ children, className, ...props }) => {
	return (
		<Container className={classNames("page", className)} {...props}>
			{children}
		</Container>
	);
};

declare type Page = typeof PageNode & {
	Contents: typeof PageContents;
	Footer: typeof PageFooter;
	Header: typeof PageHeader;
};

const Page: Page = PageNode as Page;

Page.Contents = PageContents;
Page.Footer = PageFooter;
Page.Header = PageHeader;

export default Page;

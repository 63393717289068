import { AggregateTargetQuestion } from "../../../../../../server/lib/muscat/aggregate";
import { AggregateJobWithConfig } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { ContentsState } from "../../../../types/contents-state";
import { MessageInfo } from "../../../../types/info";
import { ProgressState } from "../../../../types/progress-state";
import { initAggregateJob } from "../../../../utils/initials";

export type QMap = { [key: string]: "S" | "M" | "AS" | "AM" | "N" };

export type MatrixObj = { [quename: string]: { parent: string; child: string; type: "S" | "M" | "N" } };

export type State = {
	aggregateJob: ContentsState<Partial<AggregateJobWithConfig>>;
	job: ContentsState<Job>;
	progressState: ProgressState;
	questions: ContentsState<AggregateTargetQuestion[]>;
	qMap: QMap;
	matrixes: MatrixObj;
	id?: string;
	info?: MessageInfo;
};

export const initState = ({ jobId, type }: { jobId: string; type: "create" | "edit" }): State => {
	return {
		aggregateJob: type === "create" ? { state: "loaded", value: initAggregateJob(jobId) } : { state: "loading" },
		job: { state: "loading" },
		qMap: {},
		matrixes: {},
		progressState: "none",
		questions: { state: "loading" },
	};
};

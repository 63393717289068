import { CrossData } from "@lu/muscat-analytics-library";
import { Job } from "../../../../../../server/repositories/job/model";
import { ContentsState } from "../../../../types/contents-state";
import { ProgressState } from "../../../../types/progress-state";
import { ResultViewType } from "../../../../types/result-view-type";

export type State = {
	cross: string;
	data: ContentsState<CrossData[]>;
	job: ContentsState<Job>;
	progressState: ProgressState;
	horizontal: boolean;
	viewType: ResultViewType;
};

export const initState: State = {
	cross: "",
	data: { state: "loading" },
	job: { state: "loading" },
	progressState: "none",
	horizontal: true,
	viewType: "both",
};

import React, { FunctionComponent } from "react";
import { OptionCell } from "../option-cell";

type Props = {
	index: number;
	indexOffset: number;
	options: any[];
	selectedCols: Set<number>;
	onClickCell: (col: number, row: number) => void;
};

export const OptionCells: FunctionComponent<Props> = React.memo(
	({ index, indexOffset, options, selectedCols, onClickCell }) => {
		return (
			<>
				{options.map((option, i) => (
					<OptionCell
						key={i}
						col={indexOffset + i}
						option={option}
						row={index}
						selectedCols={selectedCols}
						onClickCell={onClickCell}
					/>
				))}
			</>
		);
	}
);

import { ErrorObject } from "@lu/validator";
import React, { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

const numberRegex = /^[0-9]+$/;

type Props = { arrayKeysSet: Set<string>; errors: ErrorObject; objectName: string; parentKey?: string };

export const ChildErrorsView: FunctionComponent<Props> = ({ arrayKeysSet, errors, objectName, parentKey }) => {
	const { t } = useTranslation();

	const makeLabel = useCallback(
		(key: string) => {
			return parentKey
				? `${t(`${objectName}.${parentKey}`)}${parseInt(key) + 1}`
				: numberRegex.test(key)
				? key
				: t(`${objectName}.${key}`);
		},
		[parentKey]
	);

	return (
		<>
			{Object.entries(errors).map(([k, v]) => (
				<div className="errors-viewer__child" key={k}>
					{!arrayKeysSet.has(k) && <span>{`${makeLabel(k)} ${typeof v === "string" ? `: ${v}` : "> "}`}</span>}
					{typeof v !== "string" && (
						<ChildErrorsView
							arrayKeysSet={arrayKeysSet}
							errors={v}
							objectName={objectName}
							{...(arrayKeysSet.has(k) && { parentKey: k })}
						/>
					)}
				</div>
			))}
		</>
	);
};

import * as React from "react";
import { Choice, AutoAnswerQuestionChoice } from "@lu/muscat-library";

type Props = {
	choices?: (Choice | AutoAnswerQuestionChoice)[];
};

export const AggregateTargetViewer: React.FC<Props> = React.memo(({ choices = [] }) => {
	return (
		<div className="aggregate-editor-page__target__viewer">
			{choices.map(({ value, text }) => (
				<div key={`choice-${value}`} className="aggregate-editor-page__target__viewer__item">
					{`${value}.${text}`}
				</div>
			))}
		</div>
	);
});

import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { SyncRawdataPage } from "../../../components/pages/aggregates/sync-rawdata";

export const SyncRawdataContainer: FunctionComponent = React.memo(() => {
	const { t } = useTranslation();

	const { id, jobId } = useParams<{ id?: string; jobId: string }>();

	useEffect(() => {
		document.title = `${t("page_title.sync_rawdata")} - ${t("page_title.site_name")}`;
	}, [id]);

	return <SyncRawdataPage id={id} jobId={jobId} />;
});

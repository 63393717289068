import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AxisLabel } from "../../../../../../../lib/aggregate/axis";
import { LabelCell } from "./label-cell";

type Props = {
	index: number;
	labels: AxisLabel[];
	rowSpan: number;
	selectedCols: Set<number>;
	onClickColHeader: (cols: number[]) => void;
};

export const LabelCells: FunctionComponent<Props> = React.memo(
	({ index, labels, rowSpan, selectedCols, onClickColHeader }) => {
		const { t } = useTranslation();

		const handleClickColHeader = useCallback(() => onClickColHeader([0]), [onClickColHeader]);

		return (
			<tr key={`axis-label-${index}`} className="cross__head">
				{index === 0 && (
					<>
						<th rowSpan={rowSpan} />
						<th
							rowSpan={rowSpan - 1}
							className={classNames("cross__col", {
								"cross__col--selected": selectedCols.has(0),
							})}
							onClick={handleClickColHeader}
						/>
					</>
				)}
				{rowSpan === index + 1 && (
					<th
						className={classNames("cross__col", {
							"cross__col--selected": selectedCols.has(0),
						})}
						onClick={handleClickColHeader}
					>
						{t("cross_area.total")}
					</th>
				)}
				{labels.map((axisLabel, i) => (
					<LabelCell
						key={`axis-label-${index}-${i}`}
						axisLabel={axisLabel}
						selectedCols={selectedCols}
						onClick={onClickColHeader}
					/>
				))}
			</tr>
		);
	}
);

import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "select_cell": {
			const { col, row } = action.payload;
			return {
				...state,
				selectedCols: state.selectedCols.has(col) && state.selectedRows.has(row) ? new Set() : new Set([col]),
				selectedRows: state.selectedCols.has(col) && state.selectedRows.has(row) ? new Set() : new Set([row]),
			};
		}
		case "select_col":
			return {
				...state,
				selectedCols: action.payload.reduce(
					(accumulator, col) => accumulator && state.selectedCols.has(col),
					state.selectedCols.size === action.payload.length
				)
					? new Set()
					: new Set(action.payload),
			};
		case "select_row":
			return {
				...state,
				selectedRows: action.payload.reduce(
					(accumulator, row) => accumulator && state.selectedRows.has(row),
					state.selectedRows.size === action.payload.length
				)
					? new Set()
					: new Set(action.payload),
			};
	}
};

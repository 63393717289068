import { SelectionGroup } from "@lu/muscat-analytics-library";
import { AggregateTargetQuestion } from "../../../../../../server/lib/muscat/aggregate";
import { ContentsState } from "../../../../types/contents-state";
import { Action } from "./action";
import { MatrixObj, State } from "./state";

const makeQMap = (questions: ContentsState<AggregateTargetQuestion[]>) => {
	if (questions.state !== "loaded") return {};
	return questions.value.reduce((a, b) => {
		return { ...a, [b.quename]: b.type };
	}, {});
};
const makeMatrixes = (questions: ContentsState<AggregateTargetQuestion[]>) => {
	if (questions.state !== "loaded") return {};
	const ret: MatrixObj = {};
	for (const q of questions.value) {
		if (q.type !== "MT") continue;
		for (const child of q.childQuestionGroups) {
			for (const childQuetion of child.questions) {
				if (childQuetion.type === "O") continue;
				ret[`${q.quename}${childQuetion.quename}`] = {
					parent: q.quename,
					child: childQuetion.quename,
					type: childQuetion.type,
				};
			}
		}
	}
	return ret;
};
const makeChoiceGroupName = (selectionGroups: SelectionGroup[]) => {
	const len = selectionGroups?.length || 0;
	const lastNumber = len ? Number(selectionGroups[len - 1].name.replace("T", "")) : 0;
	return `T${lastNumber + 1}`;
};

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "addSelectionGroup":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							selectionGroups: [
								...(state.aggregateJob.value.config?.selectionGroups || []),
								{
									name: makeChoiceGroupName(state.aggregateJob.value.config?.selectionGroups),
									label: "",
									quename: "",
									grouping: [],
								},
							],
						},
					},
				},
			};
		case "aggregate":
			return {
				...state,
				aggregateJob: action.payload,
				id: action.payload.state === "loaded" ? action.payload.value._id?.toString() : undefined,
			};
		case "axis":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							axis: action.payload,
						},
					},
				},
			};
		case "deleteSelectionGroup":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							selectionGroups: state.aggregateJob.value.config?.selectionGroups.filter((_, i) => i !== action.payload),
						},
					},
				},
			};
		case "changeSelectionGroup":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							selectionGroups: state.aggregateJob.value.config?.selectionGroups.map((selectionGroup) => {
								if (selectionGroup.name !== action.payload.targetName) return selectionGroup;
								if (action.payload.name === "quename") {
									const selectionQuename = `${selectionGroup.quename || ""}${selectionGroup.childQuename || ""}`;
									const v = action.payload.value as string;
									const matrixObj = state.matrixes[v];
									const tmp = { ...selectionGroup };
									if (matrixObj) {
										tmp.quename = matrixObj.parent;
										tmp.childQuename = matrixObj.child;
									} else {
										tmp.quename = v;
										delete tmp.childQuename;
									}
									if (selectionQuename !== v) {
										tmp.grouping = [
											(matrixObj ? matrixObj.type : state.qMap[v]) === "N"
												? { label: "", from: undefined, to: undefined }
												: { label: "", values: [] },
										];
									}
									return tmp;
								}
								return { ...selectionGroup, [action.payload.name]: action.payload.value };
							}),
						},
					},
				},
			};
		case "importType":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						import_type: action.payload,
					},
				},
			};
		case "job":
			return {
				...state,
				job: action.payload,
			};
		case "messageInfo":
			return {
				...state,
				info: action.payload,
				progressState: "done",
			};
		case "moveSelectionItem": {
			if (state.aggregateJob.state !== "loaded" || !state.aggregateJob.value.config) return state;

			const selectionGroups = state.aggregateJob.value.config.selectionGroups;

			const selectionGroup = selectionGroups[action.payload.targetIndex];

			const selectionItems = selectionGroup.grouping.splice(action.payload.sourceIndex, 1);
			selectionGroup.grouping.splice(action.payload.destinationIndex, 0, ...selectionItems);

			selectionGroups[action.payload.targetIndex] = {
				...selectionGroup,
			};

			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							selectionGroups,
						},
					},
				},
			};
		}
		case "name":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						name: action.payload,
					},
				},
			};
		case "targetQuestions":
			if (state.aggregateJob.state !== "loaded") return state;
			return {
				...state,
				aggregateJob: {
					...state.aggregateJob,
					value: {
						...state.aggregateJob.value,
						config: {
							...state.aggregateJob.value.config,
							targetQuestions: action.payload,
						},
					},
				},
			};
		case "progressState":
			return {
				...state,
				progressState: action.payload,
			};
		case "questions":
			return {
				...state,
				questions: action.payload,
				qMap: makeQMap(action.payload),
				matrixes: makeMatrixes(action.payload),
			};
		case "saveAggregate":
			return {
				...state,
				...action.payload,
				progressState: "done",
			};
	}
};

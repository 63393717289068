import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { internalApiEndpoint } from "../../../../../../server/router/api/internal/endpoint";
import { get, makeError } from "../../../../lib/request";
import Page from "../../../parts/page";
import { LoadingSpinner } from "../../../parts/loadings";
import { ErrorsViewer } from "../../../parts/errors-viewer";
import { GetJobOneResponse } from "../../../../../../server/types/request/internal/job";
import { CrossView } from "../../../parts/aggregate/cross";
import { ValidatableSelect } from "../../../parts/validatable-select";
import { ResultViewType } from "../../../../types/result-view-type";
import { ButtonsContainer } from "../../../parts/buttons-container";
import Button from "react-bootstrap/Button";
import { CrossAggregateConverter } from "../../../../lib/aggregate/converter";
import { GetCrossAggregateResponse } from "@lu/muscat-analytics-library/dist/model/api/corss-aggregate";
import { endpoint } from "../../../../routes/endpoints";
import { Link } from "react-router-dom";
import { initState } from "./state";
import { reducer } from "./reducer";
import { Dropdown } from "react-bootstrap";
import { ToggleLabelButton } from "../../../parts/buttons/toggle-label-button";

type Props = {
	id: string;
	jobId: string;
};

export const CrossResultPage: FunctionComponent<Props> = React.memo(({ id, jobId }) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		get<GetJobOneResponse>(`${internalApiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({
					type: "job",
					payload: { state: "loaded", value: res.data.job },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: "job", payload: { state: "failed", info: makeError(error) } });
			});
	}, [jobId]);

	useEffect(() => {
		get<GetCrossAggregateResponse>(`${internalApiEndpoint.aggregateCrossData}/${id}`)
			.then((res) => {
				dispatch({ type: "data", payload: { state: "loaded", value: res.data.data } });
			})
			.catch((error) => {
				dispatch({ type: "data", payload: { state: "failed", info: makeError(error) } });
			});
	}, [id]);

	const axisDirection = useMemo(() => (state.horizontal ? "horizontal" : "vertical"), [state.horizontal]);

	const crosses = useMemo(() => {
		if (state.data.state !== "loaded") return [];
		return CrossAggregateConverter.convert(state.data.value, "separate");
	}, [state.data]);

	const crossOptions = useMemo(() => {
		return [
			{ label: t("result_view_page.view_all"), value: "" },
			...crosses.map(({ condition, qunename, title }) => ({
				label: `${condition ? `【${condition}】 ` : ""}${qunename}.${title}`,
				value: `${qunename}${condition}`,
			})),
		];
	}, [crosses]);

	const filteredCross = useMemo(
		() =>
			state.cross ? [crosses.find(({ condition, qunename }) => `${qunename}${condition}` === state.cross)] : crosses,
		[crosses, state.cross]
	);

	const horizontalValues = useMemo(
		() => [
			{ label: <i className="bi-arrow-right" />, value: false },
			{ label: <i className="bi-arrow-down" />, value: true },
		],
		[]
	);

	const title = useMemo(
		() => (
			<div className="cross-result-page__header">
				<div>{t("page_title.cross_result")}</div>
				<div className="cross-result-page__header__job-title">
					<div>{t("job.enquete_title")}</div>
					<div>{state.job.state === "loaded" ? state.job.value.enquete_title : ""}</div>
				</div>
			</div>
		),
		[state.job]
	);

	const viewTypeOptions = useMemo(
		() => [
			{ label: t("result_view_type.both"), value: "both" },
			{ label: t("result_view_type.n"), value: "n" },
			{ label: t("result_view_type.percent"), value: "percent" },
		],
		[]
	);
	const handleChangeCross = useCallback((payload: string) => dispatch({ type: "cross", payload }), []);

	const handleChangeViewType = useCallback((payload: ResultViewType) => dispatch({ type: "view_type", payload }), []);

	const handleChangeHorizontal = useCallback((payload: boolean) => dispatch({ type: "horizontal", payload }), []);

	return (
		<div className="cross-result-page">
			<Page.Header title={title}>
				<ButtonsContainer align="right">
					<Button
						as={Link}
						disabled={state.progressState === "progress"}
						to={`/${jobId}/${endpoint.editAggregate}/${id}`}
						variant="outline-gray"
					>
						<i className="bi-pencil-square" />
						<span>{t("cross_result_page.edit")}</span>
					</Button>
					<Dropdown>
						<Dropdown.Toggle disabled={state.progressState === "progress"} variant="outline-gray">
							<i className="bi-download" />
							<span>{t("result_view_page.download_cross")}</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								as="a"
								download
								href={`${internalApiEndpoint.downloadAggregateCross}/${id}?axisDirection=${axisDirection}&type=both`}
							>
								{t("result_view_type.both")}
							</Dropdown.Item>
							<Dropdown.Item
								as="a"
								download
								href={`${internalApiEndpoint.downloadAggregateCross}/${id}?axisDirection=${axisDirection}&type=n`}
							>
								{t("result_view_type.n")}
							</Dropdown.Item>
							<Dropdown.Item
								as="a"
								download
								href={`${internalApiEndpoint.downloadAggregateCross}/${id}?axisDirection=${axisDirection}&type=percent`}
							>
								{t("result_view_type.percent")}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					{/* <Button
						disabled={state.progressState === "progress"}
						variant="primary"
						onClick={handleClickDownloadQuestionSheet}
					>
						<i className="bi-download" />
						<span>{t("result_view_page.download_question_sheet")}</span>
					</Button> */}
				</ButtonsContainer>
			</Page.Header>
			<Page.Contents fluid>
				<ButtonsContainer className="cross-result-page__buttons-container">
					<Button as={Link} to={`/${jobId}/${endpoint.aggregates}`} variant="white">
						<i className="bi-chevron-left" />
						<span>{t("cross_result_page.go_back")}</span>
					</Button>
				</ButtonsContainer>
				<div className="cross-result-page__select-container">
					<ToggleLabelButton
						id="horizontal"
						tooltipText={t("result_view_page.horizontal")}
						value={state.horizontal}
						values={horizontalValues}
						onChange={handleChangeHorizontal}
					/>
					<ValidatableSelect
						className="cross-result-page__select-container__view-type-select"
						options={viewTypeOptions}
						value={state.viewType}
						onChange={handleChangeViewType}
					/>
					<ValidatableSelect
						className="cross-result-page__select-container__cross-select"
						options={crossOptions}
						value={state.cross}
						onChange={handleChangeCross}
					/>
				</div>
				{state.data.state === "loaded" && (
					<CrossView crosses={filteredCross} horizontal={state.horizontal} viewType={state.viewType} />
				)}
				{state.data.state === "loading" && <LoadingSpinner />}
				{state.data.state === "failed" && <ErrorsViewer info={state.data.info} />}
			</Page.Contents>
		</div>
	);
});

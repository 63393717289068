import { AggregateJobWithConfig } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { ContentsState } from "../../../../types/contents-state";
import { MessageInfo } from "../../../../types/info";
import { ProgressState } from "../../../../types/progress-state";

export type State = {
	aggregateJob: ContentsState<Partial<AggregateJobWithConfig>>;
	job: ContentsState<Job>;
	progressState: ProgressState;
	info?: MessageInfo;
};

export const initState: State = {
	aggregateJob: { state: "loading" },
	job: { state: "loading" },
	progressState: "none",
};

import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";
import { AxisLabel } from "../../../../../../../lib/aggregate/axis";
import { hasOneOf } from "../../lib";

type Props = {
	axisLabel: AxisLabel;
	selectedCols: Set<number>;
	onClick: (cols: number[]) => void;
};

export const LabelCell: FunctionComponent<Props> = React.memo(({ axisLabel, selectedCols, onClick: onClick }) => {
	const { colSpan, rowSpan, indexes, label } = axisLabel;

	const handleClick = useCallback(() => onClick(indexes), [indexes, onClick]);

	return (
		<th
			className={classNames("cross__col", {
				"cross__col--selected": hasOneOf(selectedCols, indexes),
			})}
			onClick={handleClick}
			rowSpan={rowSpan}
			colSpan={colSpan}
		>
			{label}
		</th>
	);
});

import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AxisLabel } from "../../../../../../lib/aggregate/axis";
import { ResultViewType } from "../../../../../../types/result-view-type";
import { OptionsArea } from "../options-area";
import { ValuesRows } from "./values-rows";
import { LabelCells } from "./values-rows/label-cells";
import { OptionCells } from "./values-rows/option-cells";
import { SelectionCell } from "./values-rows/selection-cell";

type Props = {
	datas: { ns: any[][]; percents: any[][]; options?: any[][] };
	labels: AxisLabel[][];
	options: string[];
	selectedCols: Set<number>;
	selectedRows: Set<number>;
	selections: string[];
	type: ResultViewType;
	horizontal: boolean;
	onClickCell: (col: number, row: number) => void;
	onClickRowHeader: (rows: number[]) => void;
};

export const ValuesArea: FunctionComponent<Props> = React.memo(
	({
		datas,
		labels,
		options,
		selectedCols,
		selectedRows,
		selections,
		horizontal,
		type,
		onClickCell,
		onClickRowHeader,
	}) => {
		const { t } = useTranslation();

		const _labels = useMemo(
			() =>
				labels.reduce<AxisLabel[][]>(
					(accumulator, labels) => {
						labels.reduce((indexOffset, { indexes, ...label }) => {
							// indexesを2倍に
							const _label = {
								indexes: indexes.reduce((accumulator, index) => {
									accumulator.push(index * 2, index * 2 + 1);
									return accumulator;
								}, []),
								...label,
							};

							if (accumulator.length <= indexOffset) {
								accumulator.push(...[...Array(indexOffset - accumulator.length)].map(() => []), [_label]);
							} else {
								accumulator[indexOffset].push(_label);
							}

							return indexOffset + indexes.length;
						}, 1);
						return accumulator;
					},
					[
						[
							{ rowSpan: labels.length - 1, indexes: [0, 1], label: "" },
							{ indexes: [0, 1], label: t("cross_area.total") },
						],
					]
				),
			[labels]
		);

		const _options = useMemo(() => {
			if (datas.options == null) return [];
			return datas.options.reduce((accumulator, options) => {
				options.forEach((option, j) => {
					if (accumulator.length <= j) {
						accumulator.push([option]);
					} else {
						accumulator[j].push(option);
					}
				});
				return accumulator;
			}, []);
		}, [datas.options]);

		return (
			<>
				{horizontal ? (
					<>
						{_labels.map((labels, i) => (
							<ValuesRows
								key={`selection-${i}`}
								datas={datas}
								head={
									<LabelCells
										labels={labels}
										selectedRows={selectedRows}
										type={type}
										onClickRowHeader={onClickRowHeader}
									/>
								}
								index={i}
								selectedCols={selectedCols}
								selectedRows={selectedRows}
								horizontal={horizontal}
								tail={
									_options.length > i && (
										<OptionCells
											index={i}
											indexOffset={selections.length}
											options={_options[i]}
											selectedCols={selectedCols}
											onClickCell={onClickCell}
										/>
									)
								}
								type={type}
								onClickCell={onClickCell}
							/>
						))}
					</>
				) : (
					<>
						{selections.map((selection, i) => (
							<ValuesRows
								key={`selection-${i}`}
								datas={datas}
								head={<SelectionCell index={i} selection={selection} type={type} onClickRowHeader={onClickRowHeader} />}
								index={i}
								selectedCols={selectedCols}
								selectedRows={selectedRows}
								horizontal={horizontal}
								type={type}
								onClickCell={onClickCell}
							/>
						))}
						{datas.options != null && (
							<OptionsArea
								indexOffset={selections.length * 2}
								labels={options}
								options={datas.options}
								selectedCols={selectedCols}
								selectedRows={selectedRows}
								onClickCell={onClickCell}
								onClickRowHeader={onClickRowHeader}
							/>
						)}
					</>
				)}
			</>
		);
	}
);

import Big from "big.js";

/**
 * サンプルサイズの生成
 * Math.roundする
 * @param {number} input
 * @returns {number}
 */
export const makeSampleSize = (input: number): number | string => {
	if (!input) return "-";
	return new Big(input).round(0).toNumber();
};

/**
 * パーセントを生成する。
 * @param {number | undefined} v 値
 * @param {number} base ベースとなる数字
 * @param {number} decimalPoint 小数点
 * @returns {string}
 */
export const makePercent = (v: number | undefined, base: number, decimalPoint = 1): string => {
	if (!v || !base) return "-";
	const b = new Big(v).div(base).mul(100);
	// const tmp = Math.round((v / base) * 100 * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint);
	return b.toFixed(decimalPoint);
};

/**
 * 小数点を丸める
 * @param {number} v
 * @param {number} [decimalPoint=undefined]
 * @returns {string}
 */
export const round = (v: number | undefined, decimalPoint = 2): string => {
	if (!v) return "-";
	return new Big(v).toFixed(decimalPoint);
};
/**
 * 値がundefinedかどうかのチェック
 * @param {any} value
 * @returns {boolean}
 */
export const isDefined = (value: any): boolean => {
	if (value === null) return false;
	if (value === undefined) return false;
	return true;
};

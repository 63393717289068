import React, { FunctionComponent, useCallback, useMemo } from "react";
import classNames from "classnames";
import { OptionCell } from "../../values-area/values-rows/option-cell";

type Props = {
	index: number;
	indexOffset: number;
	label: string;
	options: any[];
	selectedCols: Set<number>;
	selectedRows: Set<number>;
	onClickCell: (col: number, row: number) => void;
	onClickRowHeader: (rows: number[]) => void;
};

export const OptionRow: FunctionComponent<Props> = React.memo(
	({ index, indexOffset, label, options, selectedCols, selectedRows, onClickCell, onClickRowHeader }) => {
		const _index = useMemo(() => indexOffset + index, [index, indexOffset]);

		const handleClickRowHeader = useCallback(() => onClickRowHeader([_index]), [_index, onClickRowHeader]);
		return (
			<tr
				className={classNames("cross__row", {
					"cross__row--selected": selectedRows.has(_index),
				})}
			>
				<td onClick={handleClickRowHeader}>{label}</td>
				{options.map((option, i) => (
					<OptionCell
						key={i}
						col={i}
						option={option}
						row={_index}
						selectedCols={selectedCols}
						onClickCell={onClickCell}
					/>
				))}
			</tr>
		);
	}
);
